import { HiBookOpen } from "react-icons/hi2";
import Searchbar from "../../components/shared/Searchbar";
import DataTable from "../../components/shared/Datatable";
import { useState } from "react";
import useCourses from "../../hooks/useCourses";
import useUsers from "../../hooks/useUsers";
import defaultImage from '../../assets/images/22.png';
import { useNavigate, useParams } from "react-router-dom";
import ReturnButton from "../../components/shared/ReturnButton";
import CourseDetails from "../CourseDetails";
import EditPopup from "../../components/shared/EditPopup";
import { usePopUp } from "../../hooks/usePopUp";
import useSchool from "../../hooks/useSchool";
import GenericButton from "../../components/shared/GenericButton";
import { HiDuplicate, HiPlus } from "react-icons/hi";
import CreateCourse from "../../components/courses/CreateCourse";
import useGrades from "../../hooks/useGrades";
import { TabsUnderlined } from "../../components/shared/TabNavigation";


const AdminCourses = () => {
    //Has a course been selected?
    const { course } = useCourses();

    if (course) {
        return <CourseDetails />
    }
    else {
        return <AdminCoursesOverview />
    }
}

const AdminCoursesOverview = () => {
    const { courses, loading, deleteCourse, getCourseById, updateCourse } = useCourses();
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [tab, setTab] = useState(0);

    const { school } = useSchool();
    const { showPopUp } = usePopUp();
    const { getGradesForCourse } = useGrades();

    const navigate = useNavigate();
    const { getUserByID } = useUsers();

    const columns = [
        {
            key: "name",
            label: "COURSE NAME",
            render: (name, course) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {name}
                    </div>);
            }
        },
        {
            key: "admin",
            label: "CREATOR",
            render: (adminId, course) => {
                //Get user by adminId
                const user = getUserByID(adminId);

                if (!user) {
                    return <div>Deleted user</div>
                }
                return (
                    <div className="flex items-center space-x-4">
                        <img src={user.photo_url || defaultImage} alt={`${user.firstName} ${user.lastName}`} className="h-10 w-10 rounded-full object-cover" />
                        <div>
                            <div className="text-sm font-medium text-gray-900 dark:text-white">
                                {`${user.firstName} ${user.lastName}`}
                            </div>
                            <div className="text-sm text-gray-500 dark:text-gray-300">{user.email}</div>
                        </div>
                    </div>);
            }
        },
        {
            key: "attendees",
            label: "ATTENDEES",
            render: (attendees, course) => {
                return (<div>{attendees.length}</div>)
            }
        },
        {
            key: "submissions",
            label: "SUBMISSIONS",
            render: (_, course) => {
                const submissions = getGradesForCourse(course.id).length ?? 0;
                return (<div>{submissions}</div>)
            }
        },
        {
            key: "createdAt",
            label: "DATE CREATED",
            render: (date, course) => {
                const formattedDate = date?.toDate().toISOString().split('T')[0];
                return (<div>{formattedDate}</div>)
            }
        },
        {
            key: "published",
            label: "STATUS",
            render: (published, course) => {
                return (<td className="px-6 py-4">
                    <span
                        className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${course.archived ? 'bg-grey-100 text-grey-800 dark:bg-grey-900 dark:text-grey-300' : published
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                            }`}
                    >
                        {course.archived ? "Archived" : published
                            ? 'Published'
                            : 'Not published'}
                    </span>
                </td>);
            }
        },
    ];

    const handleCreate = () => {
        showPopUp(<CreateCourse />);
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-between items-center space-x-2 p-8">
                    <div className="flex items-center">
                        <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Courses
                        </h1>
                    </div>
                    <GenericButton icon={<HiPlus />} label="Create Course" handleClick={handleCreate} />
                </div>

                {/* Searchbar */}
                <Searchbar
                    data={courses.filter(c => (tab === 1 && c.archived) || (tab !== 1 && !c.archived))} // Pass the original courses array
                    searchLabel={"course name"}
                    onFilter={setFilteredCourses} // Update the filtered data
                    filterKeys={["name"]}
                />

                <TabsUnderlined activeTab={tab} setActiveTab={setTab} tabs={["Active", "Archived"]} />

                {/* Data table */}
                <div className="flex-grow">
                    <DataTable
                        rows={filteredCourses}
                        columns={columns}
                        isLoading={loading}
                        onDeleteClick={(course) => {
                            deleteCourse(course.id);
                        }}
                        onEditClick={(courseId) => {
                            const course = getCourseById(courseId);
                            showPopUp(<EditPopup label="Course" baseUrl={`institutions/${school.id}/courses/${courseId}`} updateData={updateCourse} data={course} />);
                        }}
                        onViewClick={(courseId) => {
                            navigate(`${courseId}`);
                        }}
                        customActions={[
                            {
                                icon: <HiDuplicate />,
                                onClick: (course) => {
                                    showPopUp(<CreateCourse course={course} />);
                                }
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}


export default AdminCourses;