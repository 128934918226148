import { useContext } from "react";
import { RubricsContext } from "../contexts/RubricsContext";

const useRubrics = () => {
    const context = useContext(RubricsContext);

    if (!context) {
        throw new Error("useRubrics must be used within an RubricsProvider");
    }

    return context;
};

export default useRubrics;