import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Dropdown } from "flowbite-react";
import useGrades from '../../hooks/useGrades'
import useScenarios from "../../hooks/useScenarios";
import useCourses from "../../hooks/useCourses";
import useAssignments from "../../hooks/useAssignments";
import useUsers from "../../hooks/useUsers";

// Register the Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const calculateAvgSessionDuration = (grades) => {
    // Step 1: Accumulate total time and session counts for each course
    const courseStats = grades.reduce((acc, grade) => {
        const course = grade.courseName;
        const timeSpent = (grade.timeSpent || 1200)/ 60;

        if (!acc[course]) {
            acc[course] = { totalTime: 0, sessionCount: 0 };
        }

        acc[course].totalTime += timeSpent;
        acc[course].sessionCount += 1;

        return acc;
    }, {});

    // Step 2: Calculate the average for each course
    const avgSessionDuration = Object.entries(courseStats).reduce((result, [course, stats]) => {
        result[course] = stats.totalTime / stats.sessionCount;
        return result;
    }, {});

    return avgSessionDuration;
};

const InstitutionAnalytics = () => {
    const [selectedCourses, setSelectedCourses] = useState([]);

    const { grades, getGradesForAssignment } = useGrades();
    const { courses } = useCourses();
    const { getUserByID } = useUsers();
    const { assignments } = useAssignments();

    const hoursSpentPerCourse = grades.reduce((acc, grade) => {
        acc[grade.courseName] = (acc[grade.courseName] || 0) + ((grade.timeSpent / 60.0 / 60.0) || 1);
        return acc;
    }, {});

    const avgSessionDuration = calculateAvgSessionDuration(grades);

    const sessionsPerCourse = grades.reduce((acc, grade) => {
        acc[grade.courseName] = (acc[grade.courseName] || 0) + 1;
        return acc;
    }, {});

    const simulationsCreated = assignments.reduce((acc, assignment) => {
        const admin = getUserByID(assignment.admin);
        const name = admin ? `${admin.firstName} ${admin.lastName}` : "Deleted user"
        acc[name] = (acc[name] || 0) + 1;
        return acc;
    }, {});

    const studentsPerCourse = courses.reduce((acc, course) => {
        acc[course.name] = (acc[course.name] || 0) + course.attendees.length
        return acc;
    }, {})

    const mostSubmittedSimulations = assignments.reduce((acc, assignment) => {
        const gradesForAssignment = getGradesForAssignment(assignment.id);
        acc[assignment.name] = (acc[assignment.name] || 0) + gradesForAssignment?.length;
        return acc;
    }, {})

    const handleCourseSelection = (course) => {
        setSelectedCourses((prev) =>
            prev.includes(course)
                ? prev.filter((c) => c !== course)
                : [...prev, course]
        );
    };

    const renderChart = (label, data, color) => {
        if (!data) return null;

        const chartData = {
            labels: Object.keys(data),
            datasets: [
                {
                    label,
                    data: Object.values(data),
                    backgroundColor: Object.keys(data).map(() => color),
                    borderRadius: 10,
                },
            ],
        };

        return (
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg border">
                <h3 className="text-lg font-semibold mb-4">{label}</h3>
                <div style={{ height: "300px" }}>
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: { beginAtZero: true },
                            },
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6">
            {/* Course Filter */}
            <div className="mb-6">
                {/* <Dropdown label="Select Courses" inline={true}>
                    {Object.keys(
                        grades.student1.reduce((acc, grade) => {
                            acc[grade.courseName] = true;
                            return acc;
                        }, {})
                    ).map((course) => (
                        <div key={course} className="p-2">
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={selectedCourses.includes(course)}
                                    onChange={() => handleCourseSelection(course)}
                                    className="form-checkbox h-4 w-4"
                                />
                                <span>{course}</span>
                            </label>
                        </div>
                    ))}
                </Dropdown> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {renderChart("Hours Spent in Simulation", hoursSpentPerCourse, "#00bcd4")}
                {renderChart("Average Session Duration (min)", avgSessionDuration, "#29b6f6")}
                {renderChart("Simulations Created", simulationsCreated, "#29b6f6")}
                {renderChart("Simulations Completed", sessionsPerCourse, "#29b6f6")}
                {renderChart("Number of Students", studentsPerCourse, "#29b6f6")}
                {renderChart("Most Submitted Simulations", mostSubmittedSimulations, "#29b6f6")}
            </div>
        </div>
    );
};

export default InstitutionAnalytics;
