import React, { useEffect, useState } from "react";
import useAssignments from "../../hooks/useAssignments";
import Steps from "../shared/Steps";
import useScenarios from "../../hooks/useScenarios";
import PDFViewer from "../shared/PDFViewer";
import VR from "../../assets/images/2.png"
import { HiArrowLeft, HiArrowRight, HiCheckCircle, HiXCircle } from "react-icons/hi";
import useGrades from "../../hooks/useGrades";
import useAuth from "../../hooks/useAuth";
import GradingDetails from "../grades/GradingDetails";
import NavigationGuard from "../shared/NavigationGuard";
import { updateDocument } from "../../firebase/firestore";
import { useNavigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";

const steps = ['Prebriefing', 'Start', 'Grades', 'Debriefing', 'Reflection'];

const PerformAssignment = () => {
    const { assignment } = useAssignments();
    const [step, setStep] = useState(0);
    const { grade } = useGrades();
    const { user } = useAuth();

    useEffect(() => {
        if (grade && step < 2) {
            setStep(2);
        }
    }, [grade])

    if (!assignment) {
        return <div>Loading...</div>
    }

    const advanceStep = () => {
        if (step < steps.length - 1) {
            setStep(step + 1);
        }
    }

    const returnStep = () => {
        if (step > 0)
            setStep(step - 1)
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <h2 className="mb-10 mt-10 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">
                    {assignment.name}
                </h2>
                <Steps currentStep={step} steps={steps} />
                <div className="step-content">
                    {(() => {
                        switch (step) {
                            case 0:
                                return <Prebriefing assignment={assignment} onContinue={advanceStep} />
                            case 1:
                                return <Start assignment={assignment} onReturn={returnStep} onContinue={advanceStep} />
                            case 2:
                                return <Performance onContinue={advanceStep} />
                            case 3:
                                return <Debriefing assignment={assignment} onContinue={advanceStep} />
                            case 4:
                                return <Reflection assignment={assignment} />
                        }
                    })()}
                </div>
            </div>
        </div>
    );
}

const Prebriefing = ({ assignment, onContinue }) => {
    const { prebriefing } = useAssignments();
    const { getScenarioByID } = useScenarios();

    const scenario = getScenarioByID(assignment.scenarioId);

    return (
        <section className={'bg-white dark:bg-gray-900'}>
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
                <div className="mt-4 md:mt-0">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                        Prebriefing
                    </h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                        You can either read the prebriefing directly from the Portal here, or download the PDF using the button below.<br />Once you are ready to continue, you may press the "Continue" button.
                    </p>
                    <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <a href={prebriefing} target="_blank" rel="noopener noreferrer" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                            Download PDF
                        </a>
                        <button onClick={(e) => { e.preventDefault(); onContinue() }} className="gap-4 inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                            Continue
                            <HiArrowRight />
                        </button>
                    </div>
                </div>
                <PDFViewer pdfUrl={prebriefing} />
                <br />
                {scenario.prebriefingVideo && scenario.prebriefingVideo !== "" && <div> <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">Need more info?</h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">Need extra explanation for this assessment? Watch the video below for a more in-depth explanation.</p>
                    <iframe className="mx-auto w-full max-w-2xl h-64 rounded-lg sm:h-96" src={scenario.prebriefingVideo} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>}
            </div>
        </section>
    );
};

const Start = ({ assignment, onReturn }) => {

    return (
        <section class="bg-white dark:bg-gray-900">
            <div class="px-4 py-8 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white tracking-tight">Time to enter the simulation.</h1>
                <p class="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">You are now ready to enter the simulation.<br />Once entered, you will find your assignment in the simulation under the name: <h1 className="font-semibold">{assignment.name}</h1>
                    When you have submitted your simulation, this page will automatically continue.</p>
                <div class="flex justify-center items-center divide-x divide-gray-200 dark:divide-gray-700">
                    <img class="w-50 h-48 rounded-full border-2 border-white dark:border-gray-800" src={VR} alt="VR" />
                </div>
                <div class="flex flex-col my-10 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <button onClick={onReturn} class="gap-4 inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        <HiArrowLeft />
                        Prebriefing
                    </button>
                    {/* <button onClick={onContinue} class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                        View Performance
                    </button> */}
                </div>
                <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white tracking-tight">Need help?</h1>
                <p class="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">If you need help on setting up, you can watch the demonstration video below to help guide you in the simulation.</p>
                <iframe class="mx-auto w-full max-w-2xl h-64 rounded-lg sm:h-96"
                    src="https://www.youtube.com/embed/q2hhdvKWHXQ"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>

            </div>
        </section>
    );
};

const Performance = ({ onContinue }) => {
    const { grade } = useGrades();

    if (!grade) {
        return <div>Loading...</div>
    }

    return (
        <div className="w-full flex flex-col items-center justify-center space-y-4 pt-10">
            <h1 className="text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white">
                Great job!
            </h1>
            <p className="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 text-center">
                You can now view your performance.
                <br />
                You also have the ability to read back your interaction with the patient
                in the Transcript section.
            </p>
            <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                <button
                    onClick={onContinue}
                    className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                >
                    Debriefing
                    <svg
                        className="ml-2 -mr-1 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
            {/* GradingDetails with full width */}
            <div className="w-full">
                <GradingDetails grade={grade} isPopup={false} />
            </div>
        </div>
    );
};

const Debriefing = ({ assignment, onContinue }) => {
    const { getScenarioByID } = useScenarios();
    const { updateGradeData } = useGrades();
    const [answers, setAnswers] = useState([]);
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([]);
    const [saving, setSaving] = useState(false);

    const scenario = getScenarioByID(assignment.scenarioId);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        await updateGradeData({ debriefing: answers, debriefing2: multipleChoiceAnswers });
        setSaving(false);
        onContinue();
    };

    const handleAnswerChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);
    };

    const handleDebriefing2AnswerChange = (questionIndex, answerIndex) => {
        const newAnswers = [...multipleChoiceAnswers];
        newAnswers[questionIndex] = answerIndex;
        setMultipleChoiceAnswers(newAnswers);
    };

    return (
        <form onSubmit={handleSubmit} className={'bg-white dark:bg-gray-900'}>
            <div className="gap-8 py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
                <div className="flex items-center flex-col justify-center mb-10">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                        Debriefing
                    </h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                        Try to answer the following questions as extensively as possible.
                    </p>
                </div>
                <div className="flex justify-start flex-col items-start w-full mb-6">
                    {scenario.debriefing.map((item, index) => (
                        <div key={index} className="w-full max-w-2xl mx-auto px-4">
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">{item}</h2>
                            </div>
                            <div className="py-2 px-4 mb-4 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                                <label htmlFor="comment" className="sr-only">Your comment</label>
                                <textarea
                                    id="comment"
                                    rows="6"
                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                    value={answers[index]}
                                    className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                                    placeholder="Your answer..."
                                    required
                                ></textarea>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {scenario.debriefing_2.map((item, index) =>
                <ClosedQuestionCard
                    key={index}
                    data={item}
                    onAnswerSelected={(answerIndex) => handleDebriefing2AnswerChange(index, answerIndex)}
                    selectedAnswer={multipleChoiceAnswers[index]}
                />
            )}
            <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                <button
                    type="submit"
                    disabled={saving}
                    className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                >
                    Continue
                    <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
        </form>
    );
};

const ClosedQuestionCard = ({ data, onAnswerSelected, selectedAnswer }) => {
    const handleAnswerClick = (index) => {
        if (!selectedAnswer)
            onAnswerSelected(index);
    };

    const isCorrect = index => index === data.correctAnswer;
    const isSelected = index => index === selectedAnswer;

    return (
        <div className="mb-8 mt-8 p-6 max-w-4xl mx-auto px-4">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">{data.question}</h2>
            <div className="grid gap-4">
                {data.answers?.map((choice, index) => (
                    <div
                        key={index}
                        onClick={() => handleAnswerClick(index)}
                        className={`relative flex items-center justify-between p-4 rounded-lg cursor-pointer transition-all
                        ${isSelected(index) ? "bg-blue-100 dark:bg-blue-200 border-blue-500" : "border border-gray-300 dark:border-gray-600"}
                        ${selectedAnswer != null && !isSelected(index) ? "opacity-50" : ""}`}
                    >
                        <span className={`block w-full text-left dark:text-gray-200`}>
                            {choice}
                        </span>
                        {selectedAnswer != null && (
                            <span className="absolute inset-y-0 right-4 flex items-center">
                                {isCorrect(index) ? (
                                    <HiCheckCircle className="w-6 h-6 text-green-500" />
                                ) : isSelected(index) ? (
                                    <HiXCircle className="w-6 h-6 text-red-500" />
                                ) : null}
                            </span>
                        )}
                    </div>
                ))}
            </div>
            {selectedAnswer != null && (
                <div className="mt-4 p-4 rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                    <p className={`text-md font-semibold ${data.correctAnswer === selectedAnswer ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {data.correctAnswer === selectedAnswer ? 'Your answer was Correct!' : 'Your answer was Incorrect!'}
                    </p>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Rationale:</h3>
                    <p className="mt-2 text-md text-gray-600 dark:text-gray-300">{data.rationale}</p>
                </div>
            )}
        </div>
    );
};

const Reflection = ({ assignment }) => {
    const { role } = useAuth();
    const { getScenarioByID } = useScenarios();
    const { grade, updateGradeData, updateGrade } = useGrades();
    const scenario = getScenarioByID(assignment.scenarioId);

    const navigate = useNavigate();

    const [answers, setAnswers] = useState([]);
    const [saving, setSaving] = useState(false);

    const handleAnswerChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);
    };

    const handleConfirm = async (e) => {
        e.preventDefault();
        setSaving(true);
        await updateGradeData({ reflection: answers });
        await updateGrade({ submissionDate: serverTimestamp() });
        navigate(`/${role}/assignments`);
    }

    return (
        <form onSubmit={handleConfirm} className={'bg-white dark:bg-gray-900'}>
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
                <div className="flex items-center flex-col mb-10 md:mt-0">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                        Reflection
                    </h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                        Time to reflect on the simulation and your experience.
                    </p>
                </div>
                {scenario.reflection.map((item, index) => (
                    <div className="max-w-2xl mx-auto px-4" key={index}>
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">{item}</h2>
                        </div>
                        <div className="mb-6">
                            <div className="py-2 px-4 mb-4 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                                <label htmlFor="comment" className="sr-only">Your comment</label>
                                <textarea
                                    id="comment"
                                    rows="6"
                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                    value={answers[index]}
                                    className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                                    placeholder="Your answer..."
                                    required
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <button
                        type="submit"
                        disabled={saving}
                        className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                    >
                        Finish
                    </button>
                </div>
            </div>
        </form>
    );
}

export default PerformAssignment;