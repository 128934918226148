import React from 'react';

const Resources = ({ onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-4/5 max-w-4xl overflow-hidden">
                {/* Header */}
                <div className="flex justify-between items-center bg-gray-800 text-white px-6 py-4">
                    <h2 className="text-xl font-bold">Courseta Resources</h2>
                    <button
                        onClick={onClose}
                        className="text-white hover:text-gray-400 focus:outline-none"
                    >
                        &times;
                    </button>
                </div>

                {/* Content */}
                <div className="flex">
                    {/* Sidebar */}
                    <aside className="w-64 bg-gray-100 text-gray-800 flex flex-col">
                        <nav className="flex-1 overflow-y-auto p-4">
                            <ul className="space-y-2">
                                <li><a href="#introduction" className="block px-4 py-2 rounded hover:bg-gray-200">Introduction</a></li>
                                <li><a href="#authentication" className="block px-4 py-2 rounded hover:bg-gray-200">Authentication</a></li>
                                <li><a href="#endpoints" className="block px-4 py-2 rounded hover:bg-gray-200">Endpoints</a></li>
                                <li><a href="#examples" className="block px-4 py-2 rounded hover:bg-gray-200">Examples</a></li>
                                <li><a href="#faq" className="block px-4 py-2 rounded hover:bg-gray-200">FAQ</a></li>
                            </ul>
                        </nav>
                    </aside>

                    {/* Main Content */}
                    <main className="flex-1 p-6 overflow-y-auto">
                        {/* Introduction Section */}
                        <section id="introduction" className="mb-8">
                            <h1 className="text-2xl font-bold mb-4">Introduction</h1>
                            <p className="text-gray-700">Welcome to the API documentation. Here you will find all the information needed to interact with our API.</p>
                        </section>

                        {/* Authentication Section */}
                        <section id="authentication" className="mb-8">
                            <h2 className="text-xl font-bold mb-4">Authentication</h2>
                            <p className="text-gray-700">To access the API, you need an API key. Add it to your requests as a header:</p>
                            <pre className="bg-gray-800 text-white p-4 rounded mt-4">
                             <code>Authorization: Bearer YOUR_API_KEY</code>
                            </pre>
                        </section>

                        {/* Endpoints Section */}
                        <section id="endpoints" className="mb-8">
                            <h2 className="text-xl font-bold mb-4">Endpoints</h2>
                            <div className="mb-4">
                                <h3 className="font-semibold">GET /users</h3>
                                <p className="text-gray-700">Retrieve a list of users.</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="font-semibold">POST /users</h3>
                                <p className="text-gray-700">Create a new user.</p>
                            </div>
                        </section>

                        {/* Examples Section */}
                        <section id="examples" className="mb-8">
                            <h2 className="text-xl font-bold mb-4">Examples</h2>
                            <pre className="bg-gray-800 text-white p-4 rounded">
                            <code>curl -X GET https://api.example.com/users \
                            -H "Authorization: Bearer YOUR_API_KEY"</code>
                            </pre>
                        </section>

                        {/* FAQ Section */}
                        <section id="faq" className="mb-8">
                            <h2 className="text-xl font-bold mb-4">FAQ</h2>
                            <p className="text-gray-700">Here you can find answers to common questions about the API.</p>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Resources;
