import React, { useEffect, useState } from "react";

const Searchbar = ({ data, searchLabel, filterKeys, onFilter, customFilter = null }) => {
    const [query, setQuery] = useState("");

    //TODO: Create hook
    useEffect(() => {
        //Rerun the query whenever the data changes
        runFilter(query);
    }, [data, query])

    const runFilter = (value) => {
        // Default to the original data
        let filteredData = data;

        if (value && value.trim() !== "") {
            const query = value.toLowerCase(); // Normalize the query for case-insensitive matching

            filteredData = data.filter((item) => {
                // Use the custom filter if provided
                if (customFilter) {
                    return customFilter(item, value);
                }

                // Otherwise, search across all filter keys
                return filterKeys?.some((key) => {
                    const fieldValue = item[key]?.toString().toLowerCase() || "";

                    // Check if the entire query matches any part of the fieldValue
                    return fieldValue.includes(query);
                });
            });
        }

        // Pass the filtered data back to the parent
        onFilter(filteredData);
    };

    // Handle input change and filter the data
    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setQuery(value);
    };

    return (
        <div className="mb-4">
            <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                placeholder={`Search for ${searchLabel}`}
                value={query}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default Searchbar;