import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Dropdown, Checkbox } from "flowbite-react";
import useCourses from "../../hooks/useCourses";
import useGrades from "../../hooks/useGrades";
import { calculateArrayFieldAverage } from "../../utils/analytics";

// Register the Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function Analytics() {
    const { courses } = useCourses();
    const { getGradesForCourse } = useGrades();

    const courseResults = courses.map((course) => {
        const courseGrades = getGradesForCourse(course.id);
        const accuracy = calculateArrayFieldAverage(courseGrades, "accuracyResult");
        const communication = calculateArrayFieldAverage(courseGrades, "communicationResult");
        const safety = calculateArrayFieldAverage(courseGrades, "safetyResult");
        const answers = calculateArrayFieldAverage(courseGrades, "answersResult");
        return { ...course, accuracy: accuracy, communication: communication, safety: safety, answers: answers }
    })

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: { display: true, text: "Courses" },
                grid: { display: false },
            },
            y: {
                title: { display: true, text: "Percentage" },
                beginAtZero: true,
                max: 100,
                ticks: { stepSize: 10 },
                grid: { borderDash: [5, 5] },
            },
        },
        plugins: {
            legend: { position: "top" },
            tooltip: {
                callbacks: {
                    label: (context) =>
                        `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`,
                },
            },
        },
    };

    const renderChart = (metric, label, color) => {
        const chartData = {
            labels: courseResults.map((course) => course.name),
            datasets: [
                {
                    label,
                    data: courseResults.map((course) => course[metric]),
                    backgroundColor: color,
                    borderRadius: 10,
                },
            ],
        };

        return (
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg border">
                <h3 className="text-lg font-semibold mb-4">{label}</h3>
                <div style={{ height: "300px" }}>
                    <Bar data={chartData} options={chartOptions} />
                </div>
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6">
            {/* Student Filter Dropdown */}
            {/* <Dropdown inline label="Filter by Students">
                {students.map((student) => (
                    <Dropdown.Item key={student}>
                        <Checkbox
                            id={student}
                            checked={selectedStudents.includes(student)}
                            onChange={() => {
                                setSelectedStudents((prevSelected) =>
                                    prevSelected.includes(student)
                                        ? prevSelected.filter((s) => s !== student)
                                        : [...prevSelected, student]
                                );
                            }}
                        />
                        {student}
                    </Dropdown.Item>
                ))}
            </Dropdown> */}

            {/* Course Filter Dropdown */}
            {/* <Dropdown inline label="Filter by Courses">
                {courseData.map((course) => (
                    <Dropdown.Item key={course.courseName}>
                        <Checkbox
                            id={course.courseName}
                            checked={selectedCourses.includes(course.courseName)}
                            onChange={() => {
                                setSelectedCourses((prevSelected) =>
                                    prevSelected.includes(course.courseName)
                                        ? prevSelected.filter((c) => c !== course.courseName)
                                        : [...prevSelected, course.courseName]
                                );
                            }}
                        />
                        {course.courseName}
                    </Dropdown.Item>
                ))}
            </Dropdown> */}

            {/* Charts for each metric in two rows of two */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                {renderChart("accuracy", "Accuracy", "#00bcd4")}
                {renderChart("safety", "Safety", "#29b6f6")}
                {renderChart("communication", "Communication", "#1e88e5")}
                {renderChart("answers", "Answers", "#4db6ac")}
            </div>
        </div>
    );
}