import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { InworldContext } from '../contexts/InworldContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase/firebase';
import useAuth from './useAuth';
import useSchool from './useSchool';

export const useInworld = () => {
    const context = useContext(InworldContext);

    const { school } = useSchool();
    const { isAdmin } = useAuth();
    const { characterId } = useParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (characterId) {
            context.loadCharacter(characterId);
        }
        else {
            context.loadCharacter(null);
        }
    }, [characterId])

    const getImgPath = (firebaseId) => {
        const fromCourseta = searchParams.get("courseta") != null && isAdmin;
        return (fromCourseta ? `characters/${firebaseId}` : `institutions/${school.id}/characters/${firebaseId}`);
    }

    return { ...context, getImgPath, characterId };
};
