import React, { useState } from 'react';
import { HiBookOpen, HiChat, HiPencil } from 'react-icons/hi';
import Knowledge from './characters/Knowledge';
import { TabsUnderlined } from '../shared/TabNavigation';
import ReturnButton from '../shared/ReturnButton';
import { useInworld } from '../../hooks/useInworld';
import GeneralInfo from './characters/GeneralInfoTab';
import AdvancedInfo from './characters/AdvancedInfoTab';
import SaveButton from '../shared/SaveButton';
import { PRONOUN_OPTIONS, STAGE_OF_LIFE_OPTIONS } from '../../utils/inworld';
import { usePopUp } from '../../hooks/usePopUp';
import EditPopup from '../shared/EditPopup';
import Thumbnail from '../shared/Thumbnail';
import defaultImage from '../../assets/images/AI Character Placeholder (1).png'
import { useParams, useSearchParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import GenericButton from '../shared/GenericButton';
import Chat from './characters/Chat';

const Tabs = {
    INFO: 'General Info',
    ADVANCED: 'Advanced Info',
};

const tabs = [Tabs.INFO, Tabs.ADVANCED];

const CharacterDetails = () => {
    const { characters, userMadeCharacters, character, characterId, save, saving, isDirty, setCharacterField, getImgPath, updateCharacter } = useInworld();
    const [tab, setTab] = useState(0);

    const { showPopUp } = usePopUp();

    if (!character) return <div>Loading...</div>;

    const setCharacterName = (e) => {
        const updatedCharacterDescription = {
            ...character.defaultCharacterDescription,
            givenName: e.target.value ?? ""
        };
        setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
    };

    const handleAddNickName = (e) => {
        if (e.key === "Enter" && e.target.value.trim()) {
            const updatedNicknames = [
                ...(character.defaultCharacterDescription?.nicknames || []),
                e.target.value.trim(),
            ];
            const updatedCharacterDescription = {
                ...character.defaultCharacterDescription,
                nicknames: updatedNicknames,
            };
            setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
            e.target.value = ""; // Clear input field
        }
    };

    const handleRemoveNickName = (nameToRemove) => {
        const updatedNicknames = character.defaultCharacterDescription?.nicknames.filter(
            (name) => name !== nameToRemove
        );
        const updatedCharacterDescription = {
            ...character.defaultCharacterDescription,
            nicknames: updatedNicknames,
        };
        setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
    };

    const firebaseCharacter = userMadeCharacters.find(c => c.brainName === character.name) ?? characters.find(c => c.brainName === character.name);

    const updateCharacterThumbnail = (_, __, file) => {
        if (firebaseCharacter) {
            updateCharacter(firebaseCharacter.id, file);
        }
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">

                {/* Header Section */}
                <div className="flex items-center justify-between p-8">
                    <ReturnButton />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        AI Customization - {character.defaultCharacterDescription.givenName}
                    </h1>
                    <SaveButton hasUnsavedChanges={isDirty} saving={saving} saveChanges={save} />
                </div>

                {/* Main Section */}
                <div className="p-6 rounded-lg border flex items-start gap-6">
                    <div className="relative w-40 h-40">
                        {/* {character.defaultCharacterAssets.avatarImg ? (
                            <img
                                src={character.defaultCharacterAssets.avatarImg}
                                alt={`${character.defaultCharacterDescription?.givenName}'s avatar`}
                                className="w-40 h-40 rounded-lg"
                            />
                        ) : (
                            <div className="w-40 h-40 flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg">
                                No Avatar
                            </div>
                        )} */}
                        <Thumbnail thumbnailURL={`${getImgPath(firebaseCharacter.id)}/${firebaseCharacter.thumbnail}`} altName={firebaseCharacter.name} defaultImg={defaultImage} />
                        <button
                            onClick={() => {
                                showPopUp(<EditPopup label="Character" baseUrl={getImgPath(firebaseCharacter.id)} updateData={updateCharacterThumbnail} data={firebaseCharacter} editName={false} />);
                            }}
                            className="absolute bottom-2 right-2 p-2 bg-white dark:bg-gray-800 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                            <HiPencil className="w-6 h-6 text-gray-500 dark:text-gray-300" />
                        </button>
                    </div>

                    {/* Name Input */}
                    <div className="flex-1 flex flex-col gap-4">
                        <div className="flex-1 w-full">
                            <label className="font-bold mb-2 block" htmlFor="characterName">Name:</label>
                            <input
                                id="characterName"
                                type="text"
                                value={character.defaultCharacterDescription?.givenName ?? ""}
                                onChange={setCharacterName}
                                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg"
                            />
                        </div>


                        {/* Nickname Section */}
                        <div className="flex-1 w-full">
                            <label className="font-bold mb-2 block" htmlFor="nicknames">Nicknames:</label>
                            <div className="flex items-center flex-wrap gap-2">
                                {character.defaultCharacterDescription?.nicknames.map((name, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-300 dark:bg-gray-700 px-3 py-1 rounded-full text-sm text-gray-900 dark:text-white flex items-center"
                                    >
                                        {name}
                                        <button
                                            onClick={() => handleRemoveNickName(name)}
                                            className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                                {character.defaultCharacterDescription?.nicknames.length < 3 && (
                                    <input
                                        type="text"
                                        onKeyDown={handleAddNickName}
                                        className="bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg p-2 text-gray-900 dark:text-white"
                                        placeholder="Add nickname"
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Pronoun Dropdown */}
                    <div className="flex-1 flex flex-col gap-4 min-w-[200px]">
                        <div className="flex-1">
                            <label className="font-bold mb-1 block" htmlFor="pronoun">Pronoun:</label>
                            <select
                                id="pronoun"
                                value={character.defaultCharacterDescription?.pronoun || 'PRONOUN_UNSPECIFIED'}
                                onChange={(e) => {
                                    const updatedCharacterDescription = {
                                        ...character.defaultCharacterDescription,
                                        pronoun: e.target.value,
                                    };
                                    setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
                                }}
                                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                            >
                                {PRONOUN_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Life Stage Dropdown */}
                        <div className="flex-1">
                            <label className="font-bold mb-1 block" htmlFor="lifeStage">Life Stage:</label>
                            <select
                                id="lifeStage"
                                value={character.defaultCharacterDescription?.lifeStage || 'LIFE_STAGE_UNSPECIFIED'}
                                onChange={(e) => {
                                    const updatedCharacterDescription = {
                                        ...character.defaultCharacterDescription,
                                        lifeStage: e.target.value,
                                    };
                                    setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
                                }}
                                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                            >
                                {STAGE_OF_LIFE_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    
                    <GenericButton label="Chat" icon={<HiChat className='w-64 h-64' />} handleClick={() => {
                        showPopUp(<Chat characterId={characterId} character={character} />)
                    }} />
                </div>

                {/* Tabs */}
                <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />

                {/* Tab Content */}
                {tabs[tab] === Tabs.INFO && <GeneralInfo />}
                {tabs[tab] === Tabs.ADVANCED && <AdvancedInfo />}
            </div>
        </div>
    );
};

export default CharacterDetails;
