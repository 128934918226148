import React, { useState, useEffect } from "react";
import { HiOutlineAcademicCap, HiOutlineNewspaper, HiPlus, HiX } from "react-icons/hi";
import { usePopUp } from "../../hooks/usePopUp";
import useCourses from "../../hooks/useCourses";


const CreateCourse = ({ course = null }) => {
    const { showPopUp } = usePopUp();
    const [name, setName] = useState("");
    const { createCourse } = useCourses();

    const onClose = () => {
        showPopUp(null);
    }

    const handleSubmit = async () => {
        onClose();
        await createCourse(name, course);
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">{course ? "Duplicate" : "Create"} Course</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Course name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter course name"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            {course ? "Duplicate" : "Create"} Course
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateCourse;