import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase/firebase';
import { HiUser, HiX } from 'react-icons/hi';
import { usePopUp } from '../../../hooks/usePopUp';

const Chat = ({ characterId, character }) => {
    const { user } = useAuth();
    const [sessionId, setSessionId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");

    const { showPopUp } = usePopUp();

    const handleSend = async () => {
        if (newMessage.trim() === "") return;
        const message = newMessage;
        const sendMessage = httpsCallable(functions, "sendInworldMessage");

        setNewMessage("");
        setMessages([...messages, { text: message, isCharacter: false }]);

        try {
            console.log("Sending text: ", message);
            const response = await sendMessage({ text: message, characterId, sessionId, userName: user.firstName, userId: user.id });
            console.log("Message response:", response.data);

            setMessages([...messages, { text: message, isCharacter: false }, ...response.data.textList?.map(t => { return { text: t, isCharacter: true } })]);
        } catch (error) {
            console.error("Error opening session:", error);
        }
    };

    const onClose = () => {
        showPopUp(null);
    }

    useEffect(() => {
        if (!sessionId) {
            const openSessionForCharacter = async () => {
                const openSession = httpsCallable(functions, "sendInworldMessage");

                try {
                    console.log("Trying to open session for: ", characterId);
                    const response = await openSession({ text: "Hello", characterId, userName: user.firstName, userId: user.id });
                    console.log("Session Opened:", response.data);

                    setSessionId(response.data.sessionId);
                    setMessages([...messages, ...response.data.textList?.map(t => { return { text: t, isCharacter: true } })]);
                } catch (error) {
                    console.error("Error opening session:", error);
                }
            };
            openSessionForCharacter();
        }
    }, [])

    return <div>{!sessionId ? "Loading..." :
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Chat</h3>
                    <button
                        onClick={onClose}
                        type="button"
                        className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                        style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                    >
                        <span className="sr-only">Close modal</span>
                        <HiX />
                    </button>
                </div>

                <div className="overflow-auto bg-white max-h-[80vh]">
                    {/* Chat Messages */}
                    <div className="flex-1 overflow-y-auto p-4 space-y-4">
                        {messages.map((message, index) => (
                            <div key={index} className={`flex ${message.isCharacter ? "justify-start" : "justify-end"}`}>
                                <ChatBubble text={message.text} isCharacter={message.isCharacter} character={character} />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Chat Input */}
                <div className="flex items-center p-4 border-t border-gray-200 dark:border-gray-700">
                    <input
                        type="text"
                        className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                        onClick={handleSend}
                        className="ml-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    }
    </div>
}

const ChatBubble = ({ text, isCharacter = true, character }) => {

    console.log(character);

    return (
        <div className={`flex items-start gap-2.5 ${isCharacter ? "" : "flex-row-reverse"}`}>
            <HiUser color={isCharacter ? "blue" : "black"} className='w-16 h-16' />
            <div
                className={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 rounded-e-xl rounded-es-xl 
                    ${isCharacter ? "bg-gray-100 dark:bg-gray-700" : "bg-blue-500 text-white"}`}
            >
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <span className={`text-sm font-semibold ${isCharacter ? "text-gray-900 dark:text-white" : "text-white"}`}>
                        {isCharacter ? character.defaultCharacterDescription.givenName : "You"}
                    </span>
                    <span className={`text-sm font-normal ${isCharacter ? "text-gray-500 dark:text-gray-400" : "text-gray-200"}`}>
                        11:46
                    </span>
                </div>
                <p className={`text-sm font-normal py-2.5 ${isCharacter ? "text-gray-900 dark:text-white" : "text-white"}`}>
                    {text}
                </p>
                <span className={`text-sm font-normal ${isCharacter ? "text-gray-500 dark:text-gray-400" : "text-gray-200"}`}>
                    Delivered
                </span>
            </div>
        </div>
    );
};

export default Chat;