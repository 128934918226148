import React from "react"
import { HiHome } from 'react-icons/hi';
import { useNotification } from "../../hooks/useNotification";
import { NotificationTypes } from "../../utils/notifications";
import { usePopUp } from "../../hooks/usePopUp";

const AdminDashboard = () => {
    const {showNotification} = useNotification();
    const {showPopUp} = usePopUp();

    const handleTest1 = () =>{
        showNotification(NotificationTypes.SUCCESS, 'Succes');
    }
    const handleTest2 = () =>{
        showNotification(NotificationTypes.DANGER, 'Succes');
    }
    const handleTest3 = () =>{
        showNotification(NotificationTypes.INFO, 'Succes');
    }
    const handleTest4 = () =>{
        showNotification(NotificationTypes.WARNING, 'Succes');
    }


    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
                <div className="flex items-center mb-8">
                    <HiHome className="text-3xl text-blue-600 dark:text-blue-400" />
                    <button 
                    type="button"
                    onClick={handleTest1}
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 hover:scale-105 shadow-md transition-transform duration-300 ease-in-out"
                    style={{ height: '40px', marginRight: '10px' }}
                    >
                        TEST1
                    </button>
                    <button 
                    type="button"
                    onClick={handleTest2}
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 hover:scale-105 shadow-md transition-transform duration-300 ease-in-out"
                    style={{ height: '40px', marginRight: '10px' }}
                    >
                        TEST2
                    </button>
                    <button 
                    type="button"
                    onClick={handleTest3}
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 hover:scale-105 shadow-md transition-transform duration-300 ease-in-out"
                    style={{ height: '40px', marginRight: '10px' }}
                    >
                        TEST3
                    </button>
                    <button 
                    type="button"
                    onClick={handleTest4}
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 hover:scale-105 shadow-md transition-transform duration-300 ease-in-out"
                    style={{ height: '40px', marginRight: '10px' }}
                    >
                        TEST4
                    </button>
                    <h1 className="ml-4 text-3xl font-bold text-gray-900 dark:text-white">Dashboard</h1>
                </div>
                <h1>Put all logging for usage, events etc, especially vr stuff, like open lab usage etc</h1>
            </div>
        </div>
    );
}

export default AdminDashboard;