import React, { useState } from 'react';
import SideSteps from "../../shared/SideSteps";
import { TabsUnderlined } from "../../shared/TabNavigation";
import { prettyPrintEnum, O2Flowmeter, ConditionFrequency, BehaviourTypes, OxygenType, PatientPositionTypes, SceneType, MetaHumans, AbdominalContourTypes, AbdominalPalpation, Ataxia, BowelSoundFields, BowelSounds, ChestExpansion, ChestShape, ClubbingTypes, DirectionType, Edema, HeartRhythms, HeartSoundFields, HeartSounds, LungSoundFields, LungSounds, MotorFunction, PatientExtremity, PhysiologySystems, PulseFields, Pulses, PupilReaction, PupilShape, RashTypes, RespiratoryPattern, SensoryFunction, SensoryLocations, SkinPalpations, StrengthTypes, WoundSites, WoundTypes, InteractableType, FacialSymmetry, AbdominalPalpationWithoutLiver } from '../../../utils/ScriptingEnumLibrary';
import { MetaHumanImages } from '../../shared/MetaHumanImages';
import Accordion from '@mui/material/Accordion';
import AccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CharacterRoles } from '../../../utils/ScriptingEnumLibrary';
import Outpatient from '../../../assets/images/outpatient_clinic.jpg';
import hospitalImage from '../../../assets/images/HospitalRoom.jpg';
import MetahumanViewToggle from '../../shared/MetaHumanViewToggle';
import PathoField from './PathoField';
import PresentationField from './PresentationField';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import useScripting from '../../../hooks/useScripting';
import Thumbnail from '../../shared/Thumbnail';
import { useInworld } from '../../../hooks/useInworld';
import conditionThumbnail from '../../../assets/images/Condition Placeholder.png'
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { styled } from '@mui/material';
import defaultImage from '../../../assets/images/AI Character Placeholder (1).png'
import useSchool from '../../../hooks/useSchool';

const steps = ['Environment', 'Patient'];

const printWounds = (wound) => {
    switch (wound) {
        case WoundTypes.NoWound:
            return "No wound"
        case WoundTypes.Stage1:
            return "Stage I"
        case WoundTypes.Stage2:
            return "Stage II"
        case WoundTypes.Stage3:
            return "Stage III"
        case WoundTypes.Stage4:
            return "Stage IV"
        default:
            return "No wound"
    }
};

const SimulationTab = () => {
    const [step, setStep] = useState(0);

    return (
        <div className="flex space-x-8 min-h-screen">
            {/* SideSteps Navigation */}
            <div className="w-1/5">
                <SideSteps currentStep={step} steps={steps} setCurrentStep={setStep} />
            </div>

            {/* Main Content Area */}
            <div className="flex flex-col flex-grow">
                {/* Content based on the current step */}
                <div className="flex-grow mt-4">
                    {step === 0 && <Environment />}
                    {step === 1 && <Patient />}
                </div>
            </div>
        </div>
    );
}

const Environment = () => {
    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["Environment", "Equipment", "Phone"];
    const [characterTab, setCharacterTab] = useState(0);
    const { school } = useSchool();
    const { scenario, setField } = useScenarioCustomization();
    const [selectedEnvironment, setSelectedEnvironment] = useState(SceneType.Hospitalroom);

    const { characters, userMadeCharacters } = useInworld();

    const handleEnvironmentSelect = (environment) => {
        const sceneValue = SceneType[environment];
        setSelectedEnvironment(sceneValue);
    };

    const handleCharacterSelect = (character) => {
        setField("additionalCharacters", scenario.additionalCharacters?.includes(character.id) ? scenario.additionalCharacters.filter(c => c !== character.id) : [...scenario.additionalCharacters ?? [], character.id]);
    }

    const isComingSoon = true;

    return (
        <div className="flex flex-col flex-grow">
            <div className="container p-6 bg-white rounded-lg border dark:bg-gray-800 dark:text-gray-200">
                <TabsUnderlined
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tabs={tabs}
                />
                {tabs[activeTab] === tabs[0] && (

                    <div className='p-6 flex flex-col md:flex-row"'>
                        <h2 className="text-2xl font-semibold">Environment Model</h2>
                        <p className="text-gray-600 text-lg">Select your patient environment</p>
                        <div className="flex space-x-6 mt-6">
                            <div
                                className={`p-6 rounded-lg cursor-pointer ${selectedEnvironment === SceneType.Hospitalroom ? 'border-blue-500 border-4' : 'border-gray-300 border-2'}`}
                                onClick={() => handleEnvironmentSelect('Hospitalroom')}
                                style={{
                                    width: '250px',
                                    textAlign: 'center',
                                    borderColor: selectedEnvironment === SceneType.Hospitalroom ? '#3b82f6' : '#d1d5db',
                                    borderWidth: selectedEnvironment === SceneType.Hospitalroom ? '4px' : '2px',
                                }}
                            >
                                <img src={hospitalImage} alt="Hospital Room" className="w-full h-36 rounded-lg" />
                                <p className={`mt-4 font-semibold text-lg ${selectedEnvironment === SceneType.Hospitalroom ? 'text-blue-500' : 'text-gray-700'}`}>
                                    Inpatient Hospital Med-Surg/ICU
                                </p>
                            </div>

                            <div
                                className={`relative p-6 rounded-lg cursor-pointer ${selectedEnvironment === SceneType.OutpatientClinic
                                    ? "border-blue-500 border-4"
                                    : "border-gray-300 border-2"
                                    } ${isComingSoon ? "pointer-events-none opacity-70" : ""}`} // Disable pointer events and reduce opacity
                                onClick={() => !isComingSoon && handleEnvironmentSelect("OutpatientClinic")}
                                style={{
                                    width: "250px",
                                    textAlign: "center",
                                    borderColor:
                                        selectedEnvironment === SceneType.OutpatientClinic
                                            ? "#3b82f6"
                                            : "#d1d5db",
                                    borderWidth: selectedEnvironment === SceneType.OutpatientClinic
                                        ? "4px"
                                        : "2px",
                                }}
                            >
                                {/* Image */}
                                <img
                                    src={Outpatient}
                                    alt="Outpatient Clinic"
                                    className="w-full h-36 rounded-lg"
                                />

                                {/* Title */}
                                <p
                                    className={`mt-4 font-semibold text-lg ${selectedEnvironment === SceneType.OutpatientClinic
                                        ? "text-blue-500"
                                        : "text-gray-700"
                                        }`}
                                >
                                    Outpatient Clinic
                                </p>

                                {/* Coming Soon Overlay */}
                                {isComingSoon && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 rounded-lg">
                                        <p className="text-white text-xl font-bold">Coming Soon</p>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                )}
                {tabs[activeTab] === tabs[1] && (
                    <Equipment />
                )}
                {tabs[activeTab] === tabs[2] && (
                    <div>
                        <TabsUnderlined tabs={["Courseta AI", "My AI"]} activeTab={characterTab} setActiveTab={setCharacterTab} />
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                            {(characterTab === 0 ? characters : userMadeCharacters)?.filter(c => c.role !== CharacterRoles.PATIENT).map((character) => {
                                const path = (characterTab === 0 ? `characters/${character.id}` : `institutions/${school.id}/characters/${character.id}`);
                                return (
                                    <div
                                        key={character.id}
                                        className={`p-2 rounded-lg cursor-pointer border-2 transition duration-300 ease-in-out ${scenario.additionalCharacters?.includes(character.id) ? 'border-blue-500' : 'border-gray-300'} hover:shadow-lg`}
                                        onClick={() => handleCharacterSelect(character)}
                                    >
                                        <Thumbnail thumbnailURL={`${path}/${character.thumbnail}`} altName={character.name} defaultImg={defaultImage} />
                                        <div className='flex flex-col items-center'>
                                            <td className="p-2">
                                                <span
                                                    className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${character.role === CharacterRoles.PATIENT
                                                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                                        : character.role === CharacterRoles.FAMILY_MEMBER
                                                            ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300' : 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                                                        }`}
                                                >
                                                    {character.role}
                                                </span>
                                            </td>
                                            <div className='flex'>
                                                <p className={`mt-2 font-medium text-center ${scenario.additionalCharacters?.includes(character.id) ? 'text-blue-500' : 'text-gray-700'}`}>
                                                    {character.name}
                                                </p>
                                                <div className="relative group p-2">
                                                    <button
                                                        className="text-sm text-gray-600 dark:text-gray-400 underline hover:text-gray-800 dark:hover:text-gray-200"
                                                    >
                                                        (i)
                                                    </button>
                                                    {/* Hover Info Bubble */}
                                                    <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm p-3 rounded-lg shadow-lg z-10 max-w-xs w-max">
                                                        {character.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}


const Equipment = () => {
    const { scenario, patient, setPatientField } = useScenarioCustomization();
    const [selectedEnvironment] = useState(SceneType.Hospitalroom);

    const handleChange = (e) => {
        const updatedEquipment = { ...(patient.equipment ?? {}) };
        updatedEquipment[e.target.id] = Number(e.target.value);
        console.log(updatedEquipment);
        setPatientField('equipment', updatedEquipment);
    }

    const environmentImages = {
        0: hospitalImage,
        1: Outpatient,
    };

    return (
        <div className="p-6 flex flex-col md:flex-row">
            <div className="md:w-3/5 md:pr-6">
                <h2 className="text-2xl font-semibold mb-4">Equipment</h2>

                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">Assessment</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Stethoscope</label>
                            <select
                                name="stethoscope"
                                id={InteractableType.Stethoscope}
                                value={patient.equipment[InteractableType.Stethoscope]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Penlight</label>
                            <select
                                name="penlight"
                                id={InteractableType.Penlight}
                                value={patient.equipment[InteractableType.Penlight]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">NIHSS Clipboard</label>
                            <select
                                name="nihssClipboard"
                                id={InteractableType.Clipboard}
                                value={patient.equipment[InteractableType.Clipboard]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Other sections (Labs, Respiratory Devices, Medications) */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">Labs</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Lab Kit</label>
                            <select
                                name="labKitAssessment"
                                id={InteractableType.LabKit}
                                value={patient.equipment[InteractableType.LabKit]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Glucometer</label>
                            <select
                                name="glucometer"
                                id={InteractableType.Glucometer}
                                value={patient.equipment[InteractableType.Glucometer]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">Respiratory Devices</h3>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Resipratory Masks</label>
                        <select
                            name="labKitRespiratory"
                            id={InteractableType.RespiratoryMasks}
                            value={patient.equipment[InteractableType.RespiratoryMasks]}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>

                {/* <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">Medications</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Lab Kit</label>
                            <select
                                name="labKitMedications"
                                id={InteractableType.LabKit}
                                value={patient.equipment[InteractableType.LabKit]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">IV Pumps</label>
                            <select
                                name="ivPumps"
                                id={InteractableType.IVPumps}
                                value={patient.equipment[InteractableType.IVPumps]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="md:w-2/5 mt-6 md:mt-0">
                <img
                    src={environmentImages[selectedEnvironment]}
                    alt={selectedEnvironment === 0 ? "Inpatient Hospital" : "Outpatient Clinic"}
                    className="w-full h-auto rounded-md object-cover"
                />
                <p className="mt-2 text-center text-gray-700 font-medium">
                    {selectedEnvironment === 0 ? "Inpatient Hospital Med-Surg/ICU" : "Outpatient Clinic"}
                </p>
            </div>
        </div>
    );
};

const Patient = () => {
    const { patient, setPatientField } = useScenarioCustomization();
    const { conditions } = useScripting();
    const { characters, userMadeCharacters } = useInworld();
    const [activeTab, setActiveTab] = useState(0);
    const [characterTab, setCharacterTab] = useState(0);
    const tabs = ["Model", "AI Character", "Conditions", "Assessment", "Presentation"];

    const { school } = useSchool();

    const handleMetaHumanSelect = (metaHuman) => {
        setPatientField('metahuman', Number(metaHuman));
    };

    const handleCharacterSelect = (character) => {
        setPatientField('brainName', character.brainName);
    };

    const handleConditionSelect = (condition) => {
        const updatedConditions = patient.conditions.includes(condition.id) ? patient.conditions.filter(c => c !== condition.id) : [...patient.conditions, condition.id];
        setPatientField('conditions', updatedConditions);
    }

    const formatCharacterName = (name) => {
        const namePart = name.split('/').pop(); // Extract the last part of the name
        return namePart
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <div className="flex flex-col flex-grow">
            <div className="container p-6 bg-white rounded-lg border dark:bg-gray-800 dark:text-gray-200">
                <TabsUnderlined
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tabs={tabs}
                />
                {tabs[activeTab] === tabs[0] && (
                    <div className="p-6">
                        <h2 className="text-2xl font-semibold mb-4"> Patient Model</h2>
                        <p className="text-gray-600 text-lg mb-6">Select Your Patient Model</p>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                            {Object.entries(MetaHumans).map(([key, value]) => {
                                const images = MetaHumanImages(value);
                                return (
                                    <div
                                        key={value}
                                        className={`p-2 rounded-lg cursor-pointer border-2 transition duration-300 ease-in-out ${patient.metahuman === value ? 'border-blue-500' : 'border-gray-300'} hover:shadow-lg`}
                                        onClick={() => handleMetaHumanSelect(value)}
                                    >
                                        <img
                                            src={images.faceImage}
                                            alt={key}
                                            className="object-cover w-full h-32 md:h-40 rounded-lg"
                                        />
                                        <p className={`mt-2 font-semibold text-center ${patient.metahuman === value ? 'text-blue-500' : 'text-gray-700'}`}>
                                            {key}
                                        </p>
                                        <p className={`mt-2 font-small text-xs text-center ${patient.metahuman === value ? 'text-blue-500' : 'text-gray-700'}`}>
                                            {images.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {tabs[activeTab] === tabs[1] && (
                    <div className="p-6">
                        <h2 className="text-2xl font-semibold mb-4">AI Character</h2>
                        <p className="text-gray-600 text-lg mb-6">Select Your AI Character</p>
                        <TabsUnderlined tabs={["Courseta AI", "My AI"]} activeTab={characterTab} setActiveTab={setCharacterTab} />
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                            {(characterTab === 0 ? characters : userMadeCharacters)?.map((character) => {
                                const path = (characterTab === 0 ? `characters/${character.id}` : `institutions/${school.id}/characters/${character.id}`);
                                return (
                                    <div
                                        key={character.id}
                                        className={`p-2 rounded-lg cursor-pointer border-2 transition duration-300 ease-in-out ${patient.brainName === character.brainName ? 'border-blue-500' : 'border-gray-300'} hover:shadow-lg`}
                                        onClick={() => handleCharacterSelect(character)}
                                    >
                                        <Thumbnail thumbnailURL={`${path}/${character.thumbnail}`} altName={character.name} defaultImg={defaultImage} />
                                        <p className={`mt-2 font-medium text-center ${patient.brainName === character.brainName ? 'text-blue-500' : 'text-gray-700'}`}>
                                            {character.name}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                {tabs[activeTab] === tabs[2] && (
                    <div className="p-6">
                        <h2 className="text-2xl font-semibold mb-4">Conditions</h2>
                        <p className="text-gray-600 text-lg mb-6">Select the conditions affecting your patient. Choosing more than three may result in inaccurate pathophysiological representations.</p>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                            {conditions.map((condition) => (
                                <div
                                    key={condition.id}
                                    className={`p-2 rounded-lg cursor-pointer border-2 transition duration-300 ease-in-out ${patient.conditions.includes(condition.id) ? 'border-blue-500' : 'border-gray-300'} hover:shadow-lg`}
                                    onClick={() => handleConditionSelect(condition)}
                                >
                                    <Thumbnail altName={condition.name} thumbnailURL={`conditions/${condition.thumbnail}`} defaultImg={conditionThumbnail} />
                                    <p className={`mt-2 font-medium text-center ${patient.conditions.includes(condition.id) ? 'text-blue-500' : 'text-gray-700'}`}>
                                        {condition.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {tabs[activeTab] === tabs[3] && (
                    <>
                        <Neurological />
                        <Cardiac />
                        <Pulmonary />
                        <Gastrointestinal />
                        <Integumentary />
                        <Musculoskeletal />
                    </>
                )}
                {tabs[activeTab] === tabs[4] && (
                    <Presenation />
                )}
            </div>
        </div>
    );
}

function Presenation() {
    return (
        <div className="container mx-auto p-6 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:text-gray-200">
            <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2 pr-4">
                    <h2 className="text-2xl font-semibold">Presentation</h2>
                    <p className="text-gray-600">Define the patient's presentation</p>

                    <h3 className="text-xl font-semibold mt-6 mb-4">Vital Signs</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <PresentationField fieldName="pulse" label="Pulse" placeholder='Enter Pulse (BPM)' />
                        <PresentationField fieldName="respiratoryRate" label="Respiratory Rate" placeholder='Enter Respiratory Rate (RPM)' />
                        <PresentationField fieldName="systolicPressure" label="Systolic Pressure" placeholder='Enter Systolic' />
                        <PresentationField fieldName="diastolicPressure" label="Diastolic Pressure" placeholder='Enter Diastolic' />
                        <PresentationField fieldName="spo2" label="SPO2" placeholder='Enter SPO2 (%)' />
                        <PresentationField fieldName="bloodGlucose" label="Blood Glucose" placeholder='Enter Blood Glucose (mg/dL)' />
                        <PresentationField fieldName="temperature" label="Temperature" placeholder='Enter Temperature' />
                    </div>

                    {/* <h3 className="text-xl font-semibold mt-6 mb-4">Oxygen (W.I.P.)</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <PresentationField fieldName="applicationDevice" label="Application Device" enumType={OxygenType} />
                        <PresentationField fieldName="o2Flowmeter" label="O2 Flowmeter" enumType={O2Flowmeter} />
                    </div> */}

                    <h3 className="text-xl font-semibold mt-6 mb-4">Behavior (Non-Verbal)</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <PresentationField fieldName="behavior" label="Behaviour" enumType={BehaviourTypes} />
                        {/* <PresentationField fieldName="behaviorFrequency" label="Behaviour Frequency" enumType={ConditionFrequency} /> */}
                    </div>

                    {/* <h3 className="text-xl font-semibold mt-6 mb-4">Patient Positioning (W.I.P.)</h3>
                    <div>
                        <PresentationField fieldName="patientPosition" label="Patient Position" enumType={PatientPositionTypes} />
                    </div> */}


                </div>

                <div className="flex justify-center items-center">
                    <MetahumanViewToggle />
                </div>
            </div>
        </div>
    );
}

const CustomAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const CustomAccordionSummary = styled((props) => (
    <AccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
        transform: 'rotate(90deg)',
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Neurological() {
    return (
        <div className="container mx-auto py-2">
            <CustomAccordion>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 className='font-semibold text-xl'>Neurological</h3>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className="grid grid-cols-3 gap-4">
                        {/* Left Column - 70% */}
                        <div className="col-span-2 pr-4">
                            <h3 className="text-xl font-semibold mt-6 mb-4">Ataxia</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(PatientExtremity).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={Ataxia} pathoSystem={PhysiologySystems.Neurological} fieldName={"ataxia." + index} />
                                        </div>);
                                })}
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Sensory Functions</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(SensoryLocations).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={SensoryFunction} pathoSystem={PhysiologySystems.Neurological} fieldName={"sensory." + index} />
                                        </div>);
                                })}
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Motor Functions</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(PatientExtremity).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={MotorFunction} pathoSystem={PhysiologySystems.Neurological} fieldName={"motorFunction." + index} />
                                        </div>);
                                })}
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Pupil Shapes</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(DirectionType).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={PupilShape} pathoSystem={PhysiologySystems.Neurological} fieldName={"pupilShapes." + index} />
                                        </div>);
                                })}
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Pupil Reactions</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(DirectionType).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={PupilReaction} pathoSystem={PhysiologySystems.Neurological} fieldName={"pupilReactions." + index} />
                                        </div>);
                                })}
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Facial Symmetry</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    {/* <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label> */}
                                    <PathoField options={FacialSymmetry} pathoSystem={PhysiologySystems.Neurological} fieldName={"facialSymmetry"} />
                                </div>
                            </div>
                        </div>

                        {/* Right Column - 30% */}
                        <div className="flex justify-center items-center">
                            <MetahumanViewToggle />
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    );
}

function Cardiac() {
    return (
        <div className="container mx-auto py-2">
            <CustomAccordion>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 className='font-semibold text-xl'>Cardiac</h3>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className="grid grid-cols-3 gap-4">
                        {/* Left Column - 70% */}
                        <div className="col-span-2 pr-4">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Heart Rhythm</label>
                                    <PathoField options={HeartRhythms} pathoSystem={PhysiologySystems.Cardiac} fieldName="heartRhythm" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Pitting Edema</label>
                                    <PathoField options={Edema} pathoSystem={PhysiologySystems.Cardiac} fieldName="edema" />
                                </div>
                            </div>
                            <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Auscultation</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {Object.keys(HeartSoundFields).map((field, index) => {
                                        return (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                                <PathoField options={HeartSounds} pathoSystem={PhysiologySystems.Cardiac} fieldName={"heartSounds." + index} />
                                            </div>);
                                    })}
                                </div>
                            </div>
                            <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Pulses</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {/* Carotid Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Carotid Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.1" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Carotid Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.8" />
                                    </div>

                                    {/* Brachial Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Brachial Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.2" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Brachial Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.9" />
                                    </div>

                                    {/* Radial Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Radial Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.3" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Radial Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.10" />
                                    </div>

                                    {/* Femoral Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Femoral Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.4" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Femoral Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.11" />
                                    </div>

                                    {/* Popliteal Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Popliteal Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.5" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Popliteal Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.12" />
                                    </div>

                                    {/* Posterior Tibial Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Tibial Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.6" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Tibial Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.13" />
                                    </div>

                                    {/* Dorsalis Pedis Artery */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Dorsalis Pedis Artery (Left)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.7" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Dorsalis Pedis Artery (Right)</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.14" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Apical Impulse</label>
                                        <PathoField options={Pulses} pathoSystem={PhysiologySystems.Cardiac} fieldName="pulses.0" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* Right Column - 30% */}
                        <div className="flex justify-center items-center">
                            <MetahumanViewToggle />
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    );
}

function Pulmonary() {
    return (
        <div className="container mx-auto py-2">
            <CustomAccordion>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 className='font-semibold text-xl'>Pulmonary</h3>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 pr-4">
                            <h3 className="text-xl font-semibold mt-6 mb-4">Assessment</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Respiratory Pattern</label>
                                    <PathoField options={RespiratoryPattern} pathoSystem={PhysiologySystems.Pulmonary} fieldName={"respiratoryPattern"} />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Chest Shape</label>
                                    <PathoField options={ChestShape} pathoSystem={PhysiologySystems.Pulmonary} fieldName={"chestShape"} />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Chest Expansion</label>
                                    <PathoField options={ChestExpansion} pathoSystem={PhysiologySystems.Pulmonary} fieldName={"chestExpansion"} />
                                </div>
                            </div>
                            {/* <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Auscultation</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {Object.keys(LungSoundFields).map((field, index) => {
                                        return (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                                <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName={"lungSounds." + index} />
                                            </div>);
                                    })}
                                </div>
                            </div> */}
                            <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Auscultation</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {/* Posterior Left Superior Lobe and Posterior Right Superior Lobe */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Left Superior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.0" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Right Superior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.6" />
                                    </div>

                                    {/* Posterior Left Upper Middle Lobe and Posterior Right Upper Middle Lobe */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Left Upper Middle Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.1" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Right Upper Middle Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.7" />
                                    </div>

                                    {/* Posterior Left Lower Middle Lobe and Posterior Right Lower Middle Lobe */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Left Lower Middle Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.2" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Right Lower Middle Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.8" />
                                    </div>

                                    {/* Posterior Left Inferior Lobe and Posterior Right Inferior Lobe */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Left Inferior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.3" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Posterior Right Inferior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.9" />
                                    </div>

                                    {/* Left Anterior Superior Lobe and Right Anterior Superior Lobe */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Left Anterior Superior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.4" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Right Anterior Superior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.10" />
                                    </div>

                                    {/* Left Anterior Inferior Lobe and Right Anterior Inferior Lobe */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Left Anterior Inferior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.5" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Right Anterior Inferior Lobe</label>
                                        <PathoField options={LungSounds} pathoSystem={PhysiologySystems.Pulmonary} fieldName="lungSounds.11" />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex justify-center items-center">
                            <MetahumanViewToggle />
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    );
}

function Gastrointestinal() {
    return (
        <div className="container mx-auto py-2">
            <CustomAccordion>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 className='font-semibold text-xl'>Gastrointestinal</h3>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 pr-4">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Abdominal Contour</label>
                                    <PathoField options={AbdominalContourTypes} pathoSystem={PhysiologySystems.Gastrointestinal} fieldName={"contour"} />
                                </div>
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Palpation</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(BowelSoundFields).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={index === 0 ? AbdominalPalpation : AbdominalPalpationWithoutLiver} pathoSystem={PhysiologySystems.Gastrointestinal} fieldName={"palpations." + index} />
                                        </div>);
                                })}
                            </div>
                            <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Auscultation</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {Object.keys(BowelSoundFields).map((field, index) => {
                                        return (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                                <PathoField options={BowelSounds} pathoSystem={PhysiologySystems.Gastrointestinal} fieldName={"bowelSounds." + index} />
                                            </div>);
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <MetahumanViewToggle />
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    );
}

function Integumentary() {
    return <div className="container mx-auto py-2">
        <CustomAccordion>
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h3 className='font-semibold text-xl'>Integumentary</h3>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 pr-4">
                        <h3 className="text-xl font-semibold mt-6 mb-4">Assessment</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Generalized Rashes</label>
                                <PathoField options={RashTypes} pathoSystem={PhysiologySystems.Integumentary} fieldName={"rash"} />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Skin Palpation</label>
                                <PathoField options={SkinPalpations} pathoSystem={PhysiologySystems.Integumentary} fieldName={"palpation"} />
                            </div>
                        </div>
                        {/* <div className="col-span-2 pr-4">
                            <h3 className="text-xl font-semibold mt-6 mb-4">Wounds</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(WoundSites).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={WoundTypes} pathoSystem={PhysiologySystems.Integumentary} fieldName={"wounds." + index} customDisplay={printWounds} />
                                        </div>);
                                })}
                            </div>
                        </div> */}
                    </div>
                    <div className="flex justify-center items-center">
                        <MetahumanViewToggle />
                    </div>
                </div>
            </CustomAccordionDetails>
        </CustomAccordion>
    </div>
}

function Musculoskeletal() {
    return (
        <div className="container mx-auto py-2">
            <CustomAccordion>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 className='font-semibold text-xl'>Musculoskeletal</h3>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 pr-4">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Clubbing</label>
                                    <PathoField options={ClubbingTypes} pathoSystem={PhysiologySystems.Musculoskeletal} fieldName={"clubbing"} />
                                </div>
                            </div>
                            <h3 className="text-xl font-semibold mt-6 mb-4">Hand Grip Strength</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.keys(DirectionType).map((field, index) => {
                                    return (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                            <PathoField options={StrengthTypes} pathoSystem={PhysiologySystems.Musculoskeletal} fieldName={"handGrips." + index} />
                                        </div>);
                                })}
                            </div>
                            <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Plantar Flexion</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {Object.keys(DirectionType).map((field, index) => {
                                        return (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                                <PathoField options={StrengthTypes} pathoSystem={PhysiologySystems.Musculoskeletal} fieldName={"plantarFlexions." + index} />
                                            </div>);
                                    })}
                                </div>
                            </div>
                            <div className="col-span-2 pr-4">
                                <h3 className="text-xl font-semibold mt-6 mb-4">Dorsal Flexion</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {Object.keys(DirectionType).map((field, index) => {
                                        return (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{prettyPrintEnum(field)}</label>
                                                <PathoField options={StrengthTypes} pathoSystem={PhysiologySystems.Musculoskeletal} fieldName={"dorsalFlexions." + index} />
                                            </div>);
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <MetahumanViewToggle />
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    );
}

export default SimulationTab;