import React, { act, useEffect, useState } from "react";
import useAssignments from "../../hooks/useAssignments";
import useUsers from "../../hooks/useUsers";
import useGrades from "../../hooks/useGrades";
import DataTable from "../../components/shared/Datatable";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCourses from "../../hooks/useCourses";
import ReturnButton from "../../components/shared/ReturnButton";
import UserRow from "../../components/shared/UserRow";
import useScenarios from "../../hooks/useScenarios";
import SaveButton from "../../components/shared/SaveButton";
import { Timestamp } from "firebase/firestore";
import { convertTimePickerValueToTimestamp, convertToTimePickerValue, fromFirebaseTimestamp, toFirebaseTimestamp } from "../../utils/utils";
import { updateDocument } from "../../firebase/firestore";
import useSchool from "../../hooks/useSchool";
import { Tabs } from "flowbite-react";
import { TabsUnderlined } from "../../components/shared/TabNavigation";
import { usePopUp } from "../../hooks/usePopUp";
import { HiOutlineExclamationTriangle } from "react-icons/hi2"
import { HiAcademicCap, HiEye, HiEyeOff, HiPencil, HiPlus, HiX, HiOutlineCalendar } from "react-icons/hi";
import { SkeletonLoaderCard } from "../../components/shared/SkeletonLoader";
import DeleteButton from "../../components/shared/DeleteButton";
import GenericButton from "../../components/shared/GenericButton";
import { useNotification } from "../../hooks/useNotification";
import { NotificationTypes } from "../../utils/notifications";
import useAuth from "../../hooks/useAuth";
import PerformAssignment from "../../components/assignments/PerformAssignment";
import { sortByDate } from "../../utils/analytics";
import ConfirmPopup from "../../components/shared/ConfirmPopup";
import UserInfo from "../../components/users/UserInfo";
import Thumbnail from "../../components/shared/Thumbnail";
import CreateAssignment from "../../components/assignments/CreateAssignment";
import Roles from "../../utils/roles";

const TeacherAssignments = () => {
    const { assignment } = useAssignments();
    const [searchParams] = useSearchParams();

    const perform = searchParams.get("perform");
    const edit = searchParams.get("edit");

    if (assignment) {
        return perform != null ? <PerformAssignment /> :
            edit != null ? <EditAssignment editingAssignment={assignment} /> : <AssignmentDetails assignment={assignment} />
    }
    else {
        return <AssignmentsOverview />
    }
};

const AssignmentsOverview = () => {
    const { assignments, loading } = useAssignments();
    const { showPopUp } = usePopUp();
    const [tab, setTab] = useState(0);

    const today = new Date();
    const dueAssignments = assignments.filter((a) => { return a.published && a.dueDate.toDate() >= today });
    //Overdue is logically the rest of the assignments. This will also include assignments with invalid dates
    const overdueAssignments = assignments.filter((a) => { return a.published && !dueAssignments.includes(a) }).reverse();
    const draftAssignments = assignments.filter((a) => !a.published);

    const handleCreate = () => {
        showPopUp(<CreateAssignment />);
        setTab(1);
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                {/* Header */}
                <div className="flex justify-between items-center">
                    <div className='flex flex-wrap p-6'>
                        <HiOutlineCalendar className="text-3xl text-gray-700 dark:text-gray-300" />
                        <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Assignments</h1>
                    </div>
                    <GenericButton icon={<HiPlus />} label={"Create Assignment"} handleClick={handleCreate} />
                </div>
                <div className="flex w-full justify-between items-center">
                    <TabsUnderlined activeTab={tab} setActiveTab={setTab} tabs={["Published", "Drafts"]} />
                </div>
                {tab == 0 ?
                    (<div>< section className="mb-8">
                        <div className="flex justify-between items-center bg-green-200 px-4 py-3 rounded-lg">
                            <h2 className="text-lg font-semibold text-green-700">Active Assignments</h2>
                            <span className="text-sm text-green-700">{`(${dueAssignments.length})`}</span>
                        </div>
                        {loading ? <SkeletonLoaderCard /> : dueAssignments.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                                {dueAssignments.map((assignment) => (
                                    <AssignmentCard key={assignment.id} assignmentId={assignment.id} overdue={false} />
                                ))}
                            </div>
                        ) : (
                            <p className="mt-4 text-gray-500">No active assignments.</p>
                        )}
                    </section>
                        <section>
                            <div className="flex justify-between items-center bg-yellow-100 px-4 py-3 rounded-lg">
                                <h2 className="text-lg font-semibold text-yellow-600">Overdue Assignments</h2>
                                <span className="text-sm text-yellow-600">{`(${overdueAssignments.length})`}</span>
                            </div>
                            {loading ? <SkeletonLoaderCard /> : overdueAssignments.length > 0 ? (
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                                    {overdueAssignments.map((assignment) => (
                                        <AssignmentCard key={assignment.id} assignmentId={assignment.id} overdue={true} />
                                    ))}
                                </div>
                            ) : (
                                <p className="mt-4 text-gray-500">No overdue assignments.</p>
                            )}
                        </section></div>) : (
                        <section className="mb-8">
                            <div className="flex justify-between items-center bg-blue-200 px-4 py-3 rounded-lg">
                                <h2 className="text-lg font-semibold text-blue-700">Drafted Assignments</h2>
                                <span className="text-sm text-blue-700">{`(${draftAssignments.length})`}</span>
                            </div>
                            {loading ? <SkeletonLoaderCard /> : draftAssignments.length > 0 ? (
                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                                    {draftAssignments.map((assignment) => (
                                        <AssignmentCard key={assignment.id} assignmentId={assignment.id} overdue={false} />
                                    ))}
                                </div>
                            ) : (
                                <p className="mt-4 text-gray-500">No drafts.</p>
                            )}
                        </section>)}
            </div>
        </div >
    );
}

const AssignmentCard = ({ assignmentId, overdue }) => {
    const navigate = useNavigate();
    const { getAssignmentById, publishAssignment } = useAssignments();
    const { getCourseById } = useCourses();
    const { getGradesForAssignment } = useGrades();
    const { getScenarioByID } = useScenarios();
    const { showPopUp } = usePopUp();
    const { user } = useAuth();

    const assignment = getAssignmentById(assignmentId);
    const scenario = getScenarioByID(assignment.scenarioId);
    const course = getCourseById(assignment.courseId);
    const submissions = getGradesForAssignment(assignment.id).length;
    const attendees = course?.attendees.length;
    const admin = assignment.admin;

    const canEdit = admin === user.id;

    const handlePublish = () => {
        publishAssignment(assignment.id, !assignment.published)
    }

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg w-full">
            {/* Square image */}
            {scenario ? <Thumbnail thumbnailURL={scenario.thumbnail} altName={assignment.name} /> : <div className="w-full aspect-square">
                {<p className="flex items-center justify-center h-full text-red-500">This scenario is no longer available.</p>}
            </div>}
            <div className="p-4">
                <h3 className="text-lg font-bold text-gray-900 mb-2 truncate">
                    {assignment.name}
                </h3>
                <div className="flex justify-between items-center text-sm">
                    <p className="text-sm text-gray-500 mb-4 truncate">
                        {course ? course.name : "Deleted Course"}
                    </p>
                    <p>{`${submissions} out of ${attendees}`}</p>
                </div>
                <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600">📅 {assignment.dueDate.toDate().toLocaleString()}</span>
                    {overdue && (
                        <span className="text-red-500 font-bold">Overdue</span>
                    )}
                </div>
                <div className="mt-4 flex items-center space-x-2">
                    {assignment.published ?
                        scenario ? <button
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`${assignment.id}`)
                            }}
                        >
                            View
                        </button> : <div /> : <button
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            onClick={(e) => {
                                e.preventDefault();
                                //Show popup to confirm publish
                                showPopUp(<ConfirmPopup label={assignment.published ? "Unpublish" : "Publish"} icon={<HiOutlineExclamationTriangle />} onConfirm={handlePublish} color={"blue"} description={`Are you sure you want to ${assignment.published ? "unpublish" : "publish"}: ${assignment.name}`} />);
                            }}
                        >
                            Publish
                        </button>}
                    {canEdit && <button
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`${assignment.id}?edit`)
                        }}
                    >
                        Edit
                    </button>}
                </div>
            </div>
        </div>
    );
}

const AssignmentDetails = ({ assignment }) => {
    const { loading } = useAssignments();
    const { isAdmin, user, isTeacher, isSchoolAdmin } = useAuth();
    const { getCourseAttendees, getCourseById } = useCourses();
    const { getUserByID } = useUsers();
    const { resultBadgeStyle, resultText, getGradesForAssignment, handleGradeClick } = useGrades();
    const { showNotification } = useNotification();
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();

    const { showPopUp } = usePopUp();

    const submissions = getGradesForAssignment(assignment.id);
    const course = getCourseById(assignment.courseId);
    const courseAttendees = getCourseAttendees(course.id);

    const admin = getUserByID(assignment.admin);
    const canEdit = assignment.admin === user.id || isAdmin;
    // const attendees = [...courseAttendees, ...submissions.filter((s) => !courseAttendees.find((u) => u.id === s.userId)).map((s) => getUserByID(s.userId))]

    const submissionColumns = [
        {
            key: "name",
            label: "NAME",
            render: (_, grade) => {
                const user = getUserByID(grade.userId);
                return (
                    <UserRow user={user} />);
            }
        },
        // {
        //     key: "info",
        //     label: "INFO",
        //     render: (_, grade) => {
        //         if (!course.attendees.includes(grade.userId)) {
        //             return <p className="w-60 text-red-700">This student is not attending the assignment's course!</p>
        //         }
        //     }
        // },
        {
            key: "createdAt",
            label: "DATE SUBMITTED",
            render: (submissionDate, grade) => {
                return <div>{submissionDate ? submissionDate.toDate().toLocaleString() : "N/A"}</div>
            }
        },
        {
            key: "result",
            label: "RESULT",
            render: (_, grade) => {
                return (
                    <td className="px-6 py-4">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${resultBadgeStyle(grade ? grade.result : "N/A")}`}>
                            {resultText(grade ? grade.result : "N/A")}
                        </span>
                    </td>);
            }
        },
        {
            key: "status",
            label: "STATUS",
            render: (_, grade) => {
                return (<td className="px-6 py-4">
                    <span
                        className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${grade ? grade.submissionDate
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300' : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                            }`}
                    >
                        {grade ? grade.submissionDate
                            ? 'Completed'
                            : 'Incomplete' : 'Unsubmitted'}
                    </span>
                </td>);
            }
        },
    ];

    const attendeeColumns = [
        {
            key: "name",
            label: "NAME",
            render: (_, user) => {
                return (
                    <UserRow user={user} />);
            }
        },
        {
            key: "status",
            label: "STATUS",
            render: (_, user) => {
                return (<td className="px-6 py-4">
                    <span
                        className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
                    >
                        Unsubmitted
                    </span>
                </td>);
            }
        },
    ];

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="p-6 min-h-screen">
                    {/* Header */}
                    <div className="flex justify-between mb-2 items-center">
                        <ReturnButton />
                        <p className="text-2xl mt-6 font-semibold text-gray-900">{assignment.name}</p>
                        {canEdit && <GenericButton label={"Edit"} icon={<HiPencil />} handleClick={() => {
                            navigate(`?edit`);
                        }} />}
                    </div>
                    <div className="flex justify-between">
                        <div className="flex flex-col items-start">
                            <label className="text-xl">Status</label>
                            <p className="text-gray-600">
                                {assignment.published ? "Published" : "Hidden"}
                            </p>
                        </div>
                        {(isTeacher || isSchoolAdmin || isAdmin) && <GenericButton label={"Perform as student"} icon={<HiAcademicCap />} handleClick={() => {
                            navigate(`?perform`);
                        }} />}
                    </div>
                    <div className="w-full flex flex-col items-start mb-10">
                        <label className="text-xl">Course</label>
                        <p className="text-gray-600">
                            {course.name}
                        </p>
                    </div>
                    <div className="w-full flex flex-col items-start mb-10">
                        <label className="text-xl">Admin</label>
                        <p className="text-gray-600">
                            {admin.firstName} {admin.lastName}
                        </p>
                    </div>
                    <TabsUnderlined tabs={["Submissions", "Not submitted"]} activeTab={tab} setActiveTab={setTab} />

                    {/* Data table */}
                    <div className="flex-grow">
                        {tab == 0 ? (
                            <DataTable
                                id="submitted"
                                key="submitted"
                                rows={submissions}
                                columns={submissionColumns}
                                isLoading={loading}
                                onViewClick={(gradeId) => {
                                    handleGradeClick(gradeId);
                                }} />) :
                            (<DataTable
                                id="unsubmitted"
                                key="unsubmitted"
                                rows={courseAttendees.filter(c => !submissions.some(g => g.userId === c.id))}
                                columns={attendeeColumns}
                                isLoading={loading}
                                onViewClick={(userId) => {
                                    console.log(userId);
                                    showPopUp(<UserInfo userId={userId} onClose={() => showPopUp(null)} />);
                                }}
                            />)}
                    </div>
                </div>
            </div>
        </div>
    );
}

const EditAssignment = ({ editingAssignment = null, courseId = null }) => {
    const [assignment, setAssignment] = useState(editingAssignment);
    const [localChanges, setLocalChanges] = useState(false);
    const [saving, setSaving] = useState(false);

    const { school } = useSchool();
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();

    const { deleteAssignment, publishAssignment } = useAssignments();
    const { courses, getCourseById } = useCourses();
    const { scenarios, getScenarioById, userMadeScenarios, sharedScenarios } = useScenarios();

    const handleCreate = () => {
    }

    const handlePublishConfirm = () => {
        publishAssignment(assignment.id, !assignment.published);
        setAssignment({ ...assignment, published: !assignment.published });
    }

    const handlePublish = (e) => {
        e.preventDefault();
        showPopUp(<ConfirmPopup label={assignment.published ? "Unpublish" : "Publish"} icon={<HiOutlineExclamationTriangle />} onConfirm={handlePublishConfirm} color={"blue"} description={`Are you sure you want to ${assignment.published ? "unpublish" : "publish"}: ${assignment.name}`} />);
    }

    const handleSave = async () => {
        setLocalChanges(false);
        setSaving(true);
        await updateDocument(assignment.id, `institutions/${school.id}/assignments`, assignment);
        setSaving(false);
    }

    const handleDelete = () => {
        deleteAssignment(assignment.id);
        navigate(-1);
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="p-6 min-h-screen">
                    {/* Header */}
                    <div className="flex justify-between mb-6 w-full" >
                        <ReturnButton />
                        {editingAssignment &&
                            <SaveButton hasUnsavedChanges={localChanges} saveChanges={handleSave} saving={saving} />
                        }
                    </div>
                    <div className="mb-6 items-center">
                        <h1 className="text-2xl font-semibold text-gray-900 flex items-center">
                            {editingAssignment ? "Edit assignment" : "Create assignment"}
                        </h1>
                    </div>
                    <form onSubmit={handleCreate}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Assignment name</label>
                            <input
                                type="text"
                                id="assignmentName"
                                value={assignment.name}
                                onChange={(e) => { e.preventDefault(); setAssignment({ ...assignment, name: e.target.value }); setLocalChanges(true); }}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter assignment name"
                                required
                            />
                        </div>

                        {/* Due Date Input */}
                        <div className="mb-6">
                            <label htmlFor="formattedDueDate" className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Due Date</label>
                            <input
                                type="date"
                                id="formattedDueDate"
                                value={fromFirebaseTimestamp(assignment.dueDate)}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setAssignment({ ...assignment, dueDate: (toFirebaseTimestamp(e.target.value)) })
                                    setLocalChanges(true);
                                }}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                required
                            />
                        </div>

                        {/* Due Time Input */}
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Due time</label>
                            <input
                                type="time"
                                id="formattedDueTime"
                                value={convertToTimePickerValue(assignment.dueDate)}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setAssignment({ ...assignment, dueDate: (convertTimePickerValueToTimestamp(assignment.dueDate, e.target.value)) })
                                    setLocalChanges(true);
                                }}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                required
                            />
                        </div>

                        {/* Course Selection */}
                        {!courseId ? (
                            <fieldset className="mb-4">
                                <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">Course</legend>
                                <div className="max-h-64 overflow-y-auto mb-4">
                                    {courses.length > 0 ? (
                                        courses.map((course) => (
                                            <div key={course.id} className="course-item">
                                                <input
                                                    type="radio"
                                                    id={`course-${course.id}`}
                                                    name="course"
                                                    checked={assignment.courseId == course.id}
                                                    onChange={(e) => { setAssignment({ ...assignment, courseId: course.id }); setLocalChanges(true); }}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label htmlFor={`course-${course.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    {course.name}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No available courses</p>
                                    )}
                                </div>
                            </fieldset>
                        ) : (
                            <div className="mb-4">
                                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">Preselected Course:</p>
                                <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
                                    {getCourseById(courseId).name}
                                </div>
                            </div>
                        )}

                        {/* Scenario and Template Selection - Horizontaal naast elkaar */}
                        <div className="flex gap-4">
                            {/* Made by Courseta sectie */}
                            <fieldset className="flex-1 mb-4 border-b pb-4">
                                <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">Courseta Scenarios</legend>
                                <div className="mb-4">
                                    {scenarios.length > 0 ? (
                                        scenarios.map((scenario, index) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <input
                                                    id={`courseta-scenario-${index}`}
                                                    type="radio"
                                                    name="scenario"
                                                    value={scenario.id}
                                                    checked={assignment.scenarioId === scenario.id}
                                                    onChange={(e) => { setAssignment({ ...assignment, scenarioId: e.target.value }); setLocalChanges(true); }}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label htmlFor={`courseta-scenario-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    {scenario.name}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-gray-600 dark:text-gray-400">No available Courseta scenarios for your school.</p>
                                    )}
                                </div>
                            </fieldset>
                            {/* My Templates sectie */}
                            <fieldset className="flex-1 mb-4 border-b pb-4">
                                <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">My Scenarios</legend>
                                <div className="mb-4">
                                    {userMadeScenarios.length > 0 ? (
                                        userMadeScenarios.map((scenario, index) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <input
                                                    id={`courseta-scenario-${index}`}
                                                    type="radio"
                                                    name="scenario"
                                                    value={scenario.id}
                                                    checked={assignment.scenarioId === scenario.id}
                                                    onChange={(e) => { setAssignment({ ...assignment, scenarioId: e.target.value }); setLocalChanges(true); }}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label htmlFor={`courseta-scenario-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    {scenario.name}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-gray-600 dark:text-gray-400">You have not created any custom scenarios yet.</p>
                                    )}
                                </div>
                            </fieldset>
                            {sharedScenarios.length > 0 && <fieldset className="flex-1 mb-4 border-b pb-4">
                                <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">Shared Scenarios</legend>
                                <div className="mb-4">
                                    {sharedScenarios.map((scenario, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                id={`courseta-scenario-${index}`}
                                                type="radio"
                                                name="scenario"
                                                value={scenario.id}
                                                checked={assignment.scenarioId === scenario.id}
                                                onChange={(e) => { setAssignment({ ...assignment, scenarioId: e.target.value }); setLocalChanges(true); }}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            <label htmlFor={`courseta-scenario-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                {scenario.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>}
                        </div>
                        {!editingAssignment ? (<div className="flex w-full justify-center items-center">
                            <button type="submit" className="m-10 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                                <p className="px-4 py-2">Create Assignment</p>
                            </button>
                        </div>) : (<div className="flex flex-col w-full h-full justify-center items-center">
                            {/* <button onClick={handlePublish} className="mt-10 m-5 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                                <div className="flex items-center gap-4 px-4 py-2">
                                    {assignment.published ? <HiEyeOff /> : <HiEye />}
                                    <p>{assignment.published ? "Unpublish Assignment" : "Publish Assignment"}</p>
                                </div>
                            </button> */}
                            <GenericButton className="mt-10 m-5" label={assignment.published ? "Unpublish Assignment" : "Publish Assignment"} handleClick={handlePublish} icon={assignment.published ? <HiEyeOff /> : <HiEye />} />
                            <DeleteButton label={"Delete Assignment"} onDelete={handleDelete} />
                        </div>)}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TeacherAssignments;