import { HiOutlineTrash } from "react-icons/hi";
import { usePopUp } from "../../hooks/usePopUp";
import { HiExclamationTriangle, HiXMark } from "react-icons/hi2";
import React from "react";
import ConfirmPopup from "./ConfirmPopup";

const DeleteButton = ({ label = "Delete", iconOnly = false, onDelete }) => {
    const { showPopUp } = usePopUp();

    return (
        <button onClick={(e) => { e.preventDefault(); showPopUp(<ConfirmPopup label={label} description={"Are you sure? This action is not reversible."} onConfirm={onDelete} color={"red"} icon={<HiExclamationTriangle />} />); }} className={!iconOnly ? "m-5 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 focus:ring focus:ring-red-300" : ""}>
            {!iconOnly ? <div className="flex items-center gap-4 px-4 py-2">
                <HiOutlineTrash title='Delete' />
                <p>{label}</p>
            </div> :
                <HiOutlineTrash title='Delete' className="text-gray-500 hover:text-red-500 w-6 h-6" />
            }
        </button>
    );
}

export default DeleteButton;