import { createContext, useContext, useEffect, useState } from "react";
import { useEdgesState, useNodesState } from "reactflow";
import { useParams } from "react-router-dom";
import Roles from "../utils/roles";
import useAuth from "../hooks/useAuth";
import { addDocument, deleteDocument, setDocument, subscribeCollection, updateDocument } from "../firebase/firestore";
import { useNotification } from "../hooks/useNotification";
import { NotificationTypes } from "../utils/notifications";
import { uploadFile } from "../firebase/storage";
import useSchool from "../hooks/useSchool";

const ScriptingContext = createContext();

const ScriptingProvider = ({ children }) => {
  const { school } = useSchool();

  const [medications, setMedications] = useState([]);
  const [grades, setGrades] = useState([]);
  const [conditions, setConditions] = useState([]);

  const [medicationsMap, setMedicationsMap] = useState(new Map());
  const [gradesMap, setGradesMap] = useState(new Map());
  const [conditionsMap, setConditionsMap] = useState(new Map());

  const [loading, setLoading] = useState(true);

  const { role } = useAuth();
  const { showNotification } = useNotification();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const updateGradesMap = (data) => {
    setGrades((prevGrades) => {
      const newGrades = [...prevGrades, ...data];
      return newGrades;
    });
    setLoading(false);
    setGradesMap((prevGradesMap) => {
      const newGradesMap = new Map(prevGradesMap);
      data.forEach((item) => {
        newGradesMap.set(item.id, item);
      })
      return newGradesMap;
    });
  }


  useEffect(() => {
    if (school && (role === Roles.ADMIN || role === Roles.TEACHER || role === Roles.SCHOOLADMIN)) {
      const unsubscribeMedications = subscribeCollection(`medications`, (data) => {
        console.log("Refreshed medications: ", data)
        setMedications(data);
        setLoading(false);
        if (data)
          setMedicationsMap(new Map(data.map((item) => [item.id, item])));
        else
          setMedicationsMap(new Map());
      });

      const unsubscribeConditions = subscribeCollection(`conditions`, (data) => {
        console.log("Refreshed conditions: ", data)
        setConditions(data);
        setLoading(false);
        if (data)
          setConditionsMap(new Map(data.map((item) => [item.id, item])));
        else
          setConditionsMap(new Map());
      });

      const unsubscribeGradesScripting = subscribeCollection(`gradesScripting`, (data) => {
        console.log("Refreshed Courseta Rubrics (`gradesScripting`): ", data);
        updateGradesMap(data);
        // setGrades(data);
        // setLoading(false);
        // if (data)
        //   setGradesMap(new Map(data.map((item) => [item.id, item])));
        // else
        //   setGradesMap(new Map());
      });

      const unsubscribeUserMadeRubrics = subscribeCollection(`institutions/${school.id}/rubrics`, (data) => {
        console.log("Refreshed user made Rubrics: ", data);
        updateGradesMap(data);
      });

      return () => {
        unsubscribeMedications();
        unsubscribeConditions();
        unsubscribeGradesScripting();
        if (school) {
          unsubscribeUserMadeRubrics();
        }
      } // Cleanup on unmount
    } else {
      console.log("Cleared scripting data.");
      setMedications([]);
      setMedicationsMap(new Map());
      setGrades([]);
      setGradesMap(new Map());
      setConditions([]);
      setConditionsMap(new Map());
      setLoading(true);
    }
  }, [role])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (scenario != null) {
  //       let id = scenario.scriptId;
  //       if (!id) {
  //         id = await addDocument({ nodes: [], edges: [] }, scriptDataCollection);
  //         await updateDocument(scenario.id, scenariosCollection, { scriptId: id });
  //       }
  //       setScriptId(id);
  //       const data = await fetchDataObject(id, scriptDataCollection);
  //       if (data == null) {
  //         await addDocument({ nodes: [], edges: [] }, scriptDataCollection, id);
  //       }
  //       //console.log(data);
  //       setNodes(data?.nodes);
  //       setEdges(data?.edges);
  //     }
  //     else if (medicationId != null) {
  //       const data = await fetchDataObject(medicationId, medicationsCollection);
  //       //console.log(data);
  //       setNodes(data?.nodes ?? []);
  //       setEdges(data?.edges ?? []);
  //     }
  //     else if (conditionId != null) {
  //       const data = await fetchDataObject(conditionId, conditionsCollection);
  //       //console.log(data);
  //       setNodes(data?.nodes ?? []);
  //       setEdges(data?.edges ?? []);
  //     }
  //     else if (gradesscriptId != null) { // Handle Grade Scripting
  //       const data = await fetchDataObject(gradesscriptId, gradesScriptingCollection);
  //       //console.log(data);
  //       setNodes(data?.nodes ?? []);
  //       setEdges(data?.edges ?? []);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const loadData = (data) => {
    if (data && (nodes.length || edges.length)) return;

    setNodes(data?.nodes ?? []);
    setEdges(data?.edges ?? []);
  }

  const saveMedication = async (id) => {
    try {
      setLoading(true);
      await updateDocument(id, `medications`, { nodes: nodes, edges: edges });
      showNotification(NotificationTypes.SUCCESS, "Medication saved successfully.");
    }
    catch (error) {
      console.error("Error saving script data: ", error);
      showNotification(NotificationTypes.DANGER, "An error occured");
    }
  };

  const saveCondition = async (id) => {
    try {
      setLoading(true);
      await updateDocument(id, `conditions`, { nodes: nodes, edges: edges });
      showNotification(NotificationTypes.SUCCESS, "Condition saved successfully.");
    }
    catch (error) {
      console.error("Error saving script data: ", error);
      showNotification(NotificationTypes.DANGER, "An error occured");
    }
  };

  const saveGradeScripting = async (id, path) => {
    try {
      setLoading(true);
      await updateDocument(id, path, { nodes: nodes, edges: edges });
      showNotification(NotificationTypes.SUCCESS, "Grade scripting saved successfully.");
    }
    catch (error) {
      console.error("Error saving script data: ", error);
      showNotification(NotificationTypes.DANGER, "An error occured");
    }
  };

  const saveScenarioScripting = async (id, path) => {
    try {
      setLoading(true);
      await setDocument(id, path, { nodes: nodes, edges: edges });
      console.log("Saved script data: ", path + id);
      showNotification(NotificationTypes.SUCCESS, "Scenario scripting saved successfully.");
    }
    catch (error) {
      console.error("Error saving script data: ", error);
      showNotification(NotificationTypes.DANGER, "An error occured");
    }
  };

  const addMedication = async (name) => {
    try {
      setLoading(true);
      const id = await addDocument({ name: name, nodes: [], edges: [] }, `medications`);
      showNotification(NotificationTypes.SUCCESS, `Created medication: ${name} successfully.`);
      return id;
    }
    catch (error) {
      console.error(error);
      showNotification(NotificationTypes.DANGER, "An error occured.");
      setLoading(false);
    }
  }

  const updateMedication = async (medicationId, name, thumbnail) => {
    try {
      setLoading(true);
      if (thumbnail) {
        await uploadFile(thumbnail, `medications`);
      }
      await updateDocument(medicationId, `medications`, thumbnail ? { name: name, thumbnail: thumbnail?.name ?? "" } : { name: name });
      showNotification(NotificationTypes.SUCCESS, "Medication updated succesfully.");
    }
    catch (error) {
      console.error("Error updating medication: ", error);
      showNotification(NotificationTypes.DANGER, "An error occured.");
      setLoading(false);
    }
  }

  const deleteMedication = async (medicationId) => {
    try {
      setLoading(true);
      const id = await deleteDocument(medicationId, `medications`);
      showNotification(NotificationTypes.SUCCESS, `Medication deleted successfully.`);
      return id;
    }
    catch (error) {
      console.error(error);
      showNotification(NotificationTypes.DANGER, "An error occured.");
      setLoading(false);
    }
  }

  const addCondition = async (name) => {
    try {
      setLoading(true);
      const id = await addDocument({ name: name, nodes: [], edges: [] }, `conditions`);
      showNotification(NotificationTypes.SUCCESS, `Created condition: ${name} successfully.`);
      return id;
    }
    catch (error) {
      console.error(error);
      showNotification(NotificationTypes.DANGER, "An error occured.");
      setLoading(false);
    }
  }

  const updateCondition = async (conditionId, name, thumbnail) => {
    try {
      setLoading(true);
      if (thumbnail) {
        await uploadFile(thumbnail, `conditions`);
      }
      await updateDocument(conditionId, `conditions`, thumbnail ? { name: name, thumbnail: thumbnail?.name ?? "" } : { name: name });
      showNotification(NotificationTypes.SUCCESS, "Condition updated succesfully.");
    }
    catch (error) {
      console.error("Error updating condition: ", error);
      showNotification(NotificationTypes.DANGER, "An error occured.");
      setLoading(false);
    }
  }

  const deleteCondition = async (conditionId) => {
    try {
      setLoading(true);
      const id = await deleteDocument(conditionId, `conditions`);
      showNotification(NotificationTypes.SUCCESS, `Condition deleted successfully.`);
      return id;
    }
    catch (error) {
      console.error(error);
      showNotification(NotificationTypes.DANGER, "An error occured.");
      setLoading(false);
    }
  }

  {/* Moved to RubriscContext.js */ }
  //   const addGradesScripting = async (name) => {
  //     try {
  //       setLoading(true);
  //       const id = await addDocument({ name: name, nodes: [], edges: [] }, `gradesScripting`);
  //       showNotification(NotificationTypes.SUCCESS, `Created grade scripting: ${name} successfully.`);
  //       return id;
  //     }
  //     catch (error) {
  //       console.error(error);
  //       showNotification(NotificationTypes.DANGER, "An error occured.");
  //       setLoading(false);
  //     }
  //   }

  //   const updateGradesScripting = async (gradesscriptId, name, thumbnail) => {
  //     try {
  //       setLoading(true);
  //       if (thumbnail) {
  //         await uploadFile(thumbnail, `gradesScripting`);
  //       }
  //       await updateDocument(gradesscriptId, `gradesScripting`, thumbnail ? { name: name, thumbnail: thumbnail?.name ?? "" } : { name: name });
  //       showNotification(NotificationTypes.SUCCESS, "Grade scripting updated succesfully.");
  //     }
  //     catch (error) {
  //       console.error("Error updating grades scripting: ", error);
  //       showNotification(NotificationTypes.DANGER, "An error occured.");
  //       setLoading(false);
  //     }
  //   }

  //   const deleteGradesScripting = async (gradesscriptId) => {
  //     try {
  //       setLoading(true);
  //       const id = await deleteDocument(gradesscriptId, `gradesScripting`);
  //       showNotification(NotificationTypes.SUCCESS, `Grade scripting deleted successfully.`);
  //       return id;
  //     }
  //     catch (error) {
  //       console.error(error);
  //       showNotification(NotificationTypes.DANGER, "An error occured.");
  //       setLoading(false);
  //     }
  //   }

  const generateId = (prefix) => `${prefix}-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  const addNode = (type, position) => {
    // setScriptData((prev) => { return { ...prev, nodes: (prev.nodes ?? []).concat({ id: generateId("node"), type, position }) } });
    const newNode = {
      id: generateId("node"),
      type,
      position,
    };
    setNodes((prev) => {
      return (prev ?? []).concat(newNode)
    });
  }

  const updateNodeData = (id, newData) => {
    // const _ = require('lodash');
    setNodes((prev) => {
      return prev.map(n => {
        if (n.id === id) {
          return { ...n, data: { ...n.data, ...newData } }
        }
        return n;
      })
    });
  }

  const removeNodeData = (id, field) => {
    setNodes((prev) => {
      return prev.map(n => {
        if (n.id === id) {
          console.log("removeNodeData, prev: ", prev);
          const { [field]: _, ...newData } = n.data;
          return { ...n, data: newData }
        }
        return n;
      })
    });
  }

  const addEdge = (params) => {
    setEdges((prev) => {
      //Updated to ensure no duplicates are allowed
      const newEdge = {
        id: generateId("edge"),
        source: params.source,
        target: params.target,
        sourceHandle: params.sourceHandle,
        targetHandle: params.targetHandle,
      };

      const edgeExists = prev?.some(
        (edge) =>
          edge.source === newEdge.source &&
          edge.target === newEdge.target &&
          edge.sourceHandle === newEdge.sourceHandle &&
          edge.targetHandle === newEdge.targetHandle
      );

      if (edgeExists) {
        return prev;
      } else {
        return (prev ?? []).concat(newEdge);
      }
    })
    //setEdges((prev) => (prev ?? []).concat({ id: generateId("edge"), source: params.source, target: params.target, sourceHandle: params.sourceHandle, targetHandle: params.targetHandle }));
  };


  return (
    <ScriptingContext.Provider
      value={{
        medications,
        conditions,
        grades,
        medicationsMap,
        conditionsMap,
        gradesMap,
        addMedication,
        updateMedication,
        deleteMedication,
        addCondition,
        updateCondition,
        deleteCondition,
        // addGradesScripting,
        // updateGradesScripting,
        // deleteGradesScripting,
        loadData,
        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        addNode,
        addEdge,
        updateNodeData,
        removeNodeData,
        saveMedication,
        saveCondition,
        saveGradeScripting,
        saveScenarioScripting,
        loading
      }}
    >
      {children}
    </ScriptingContext.Provider>
  );
}

export { ScriptingProvider, ScriptingContext };