import React, { useState, useEffect } from "react";
import { usePopUp } from "../hooks/usePopUp";
import { TabsUnderlined } from "../components/shared/TabNavigation";
import Searchbar from "../components/shared/Searchbar";
import { HiBookOpen, HiX, HiTrash, HiClipboardCopy, HiPencil } from "react-icons/hi";
import { Dropdown, DropdownItem } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useScenarios from "../hooks/useScenarios";
import useAuth from "../hooks/useAuth";
import Thumbnail from "../components/shared/Thumbnail";
import useSchool from "../hooks/useSchool";
import { Navigate, useNavigate } from "react-router-dom";
import CreateAssignment from "../components/assignments/CreateAssignment";
import ConfirmPopup from "../components/shared/ConfirmPopup";
import { HiExclamationTriangle } from "react-icons/hi2";
import { SkeletonLoaderCard } from "../components/shared/SkeletonLoader";
import { NotificationTypes } from "../utils/notifications";
import { useNotification } from "../hooks/useNotification";
import Empty from "../components/shared/Empty";
import useUsers from "../hooks/useUsers";
import { useInworld } from "../hooks/useInworld";
import imgCharacterPlaceholder from '../assets/images/AI Character Placeholder (1).png'
import imgRubricPlaceholder from '../assets/images/Rubric Image Placeholder.png'
import PDFViewer from "../components/shared/PDFViewer";
import { getFileUrl } from "../firebase/storage";
import Roles from "../utils/roles";
import ReturnButton from "../components/shared/ReturnButton";
import SaveButton from "../components/shared/SaveButton";
import DeleteButton from "../components/shared/DeleteButton";
import { CharacterRoles } from "../utils/ScriptingEnumLibrary";
import useScripting from "../hooks/useScripting";
import useRubrics from "../hooks/useRubrics";

const Tabs = {
   COURSETA: "Courseta Scenarios",
   CUSTOM: "Custom Scenarios",
   RUBRICS: "Rubrics",
   AI: "AI Characters",
   SHARED: "Shared With You"
}

const RubricsTabs = {
   COURSETA_RUBRICS: "Courseta Rubrics",
   USER_RUBRICS: "My Rubrics"
}

const AITabs = {
   COURSETA: "Courseta AI",
   USERAI: "My AI"
}

// const tabs = [Tabs.COURSETA, Tabs.CUSTOM, Tabs.RUBRICS, Tabs.AI, Tabs.SHARED];
const tabs = [Tabs.COURSETA, Tabs.CUSTOM, Tabs.RUBRICS, Tabs.AI, Tabs.SHARED];
const rubricsTabs = [RubricsTabs.COURSETA_RUBRICS, RubricsTabs.USER_RUBRICS];
const aiTabs = [AITabs.COURSETA, AITabs.USERAI];

const Library = () => {
   const { role, isAdmin, user } = useAuth();

   const [tab, setTab] = useState(0);
   const [aiTab, setAITab] = useState(0);
   const [rubricsTab, setRubricsTab] = useState(0);

   const { scenarios, allScenarios, userMadeScenarios, sharedScenarios, loading } = useScenarios();
   const [filteredScenarios, setFilteredScenarios] = useState([]);
   const [filteredSharedScenarios, setFilteredSharedScenarios] = useState([]);
   const [filteredUserScenarios, setFilteredUserScenarios] = useState([]);

   const { coursetaRubrics, userMadeRubrics } = useRubrics();
   const [filteredRubrics, setFilteredRubrics] = useState([]);
   const [filteredUserRubrics, setFilteredUserRubrics] = useState([]);

   const { characters, userMadeCharacters, loadings, error } = useInworld();
   const [filteredCharacters, setFilteredCharacters] = useState([]);
   const [filteredUserCharacters, setFilteredUserCharacters] = useState([]);

   const defaultCharacterImage = 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png';

   // const extractCharacterData = (character) => ({
   //    name: character.defaultCharacterDescription?.givenName || 'Unnamed',
   //    avatarImg: character.defaultCharacterAssets?.avatarImg || defaultCharacterImage,
   //    description: character.defaultCharacterDescription?.description || 'No description provided',
   //    characterId: character.name.split('/').pop(),
   // });

   const getSearchData = () => {
      switch (tabs[tab]) {
         case Tabs.COURSETA:
            return role === Roles.ADMIN ? allScenarios : scenarios;
         case Tabs.CUSTOM:
            return userMadeScenarios.filter(s => isAdmin || s.admin === user.id);
         case Tabs.RUBRICS:
            return rubricsTab === 0 ? coursetaRubrics : userMadeRubrics;
         case Tabs.AI:
            return aiTab === 0 ? characters : userMadeCharacters;
         case Tabs.SHARED:
            return sharedScenarios;
         default:
            return [];
      }
   }

   const setSearchData = (data) => {
      switch (tabs[tab]) {
         case Tabs.COURSETA:
            setFilteredScenarios(data);
            break;
         case Tabs.CUSTOM:
            setFilteredUserScenarios(data);
            break;
         case Tabs.RUBRICS:
            setFilteredRubrics(data);
            setFilteredUserRubrics(data);
            break;
         case Tabs.AI:
            setFilteredCharacters(data);
            setFilteredUserCharacters(data);
            break;
         case Tabs.SHARED:
            setFilteredSharedScenarios(data);
            break;
      }
   }

   return (
      <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
         <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full h-full flex flex-col">
            <div className="flex items-center space-x-2 p-8">
               <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
               <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Library
               </h1>
            </div>
            <Searchbar
               data={getSearchData()}
               searchLabel={"Scenarios, Rubrics and Characters"}
               filterKeys={["name"]}
               onFilter={setSearchData}
            />
            <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />
            {<div>
               {tabs[tab] === Tabs.COURSETA && (
                  <div>
                     {loading ? <SkeletonLoaderCard /> : <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {role === Roles.ADMIN && <AddNewCourseCard label1={'Create New'} label2={'Click here to create a new Courseta Scenario'} fromCourseta={true} />}
                        {filteredScenarios.map((scenario) => (
                           <LibraryCard
                              key={scenario.id}
                              scenario={scenario}
                              fromCourseta={true}
                           />
                        ))}
                     </div>}
                  </div>
               )}
               {tabs[tab] === Tabs.CUSTOM && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-visible">
                     <AddNewCourseCard label1={'Create New'} label2={'Click Here to Create Your Own Scenario!'} />
                     {filteredUserScenarios.map((scenario) => (
                        <LibraryCard
                           key={scenario.id}
                           scenario={scenario}
                        />
                     ))}
                  </div>
               )}
               {tabs[tab] === Tabs.RUBRICS &&
                  // <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-visible">
                  //    {/* <AddNewCourseCard label1={'Create New'} label2={'Click Here to Create Your Own Grading Rubrics!'} />
                  //    {filteredUserScenarios.map((rubrics) => (
                  //       <LibraryCard
                  //          key={rubric.id}
                  //          scenario={rubric}
                  //       />
                  //    ))} */}
                  // </div>
                  //   <div>
                  //       <GradingRubric />
                  //   </div>
                  <div>
                     <div>
                        <TabsUnderlined tabs={rubricsTabs} activeTab={rubricsTab} setActiveTab={setRubricsTab} />
                     </div>
                     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                        { /*Copied big yikes code : D */}
                        {/* **insert surprised_pikachu_face.gif** */}
                        {role === Roles.ADMIN && rubricsTab === 0 && <AddNewRubricCard fromCourseta={true} />}
                        {rubricsTab === 1 && <AddNewRubricCard fromCourseta={false} />}
                        {(rubricsTab === 0 ? filteredRubrics : filteredUserRubrics).map((rubric) => (
                           <RubricCard rubric={rubric} fromCourseta={rubricsTab === 0} />
                        ))}
                     </div>
                  </div>
               }
               {tabs[tab] === Tabs.AI && <CharacterLibrary aiTab={aiTab} setAITab={setAITab} characters={aiTab === 0 ? filteredCharacters : filteredUserCharacters} />
               }
               {tabs[tab] === Tabs.SHARED && (
                  filteredSharedScenarios.length > 0 ?
                     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {filteredSharedScenarios.map((scenario) => (
                           <LibraryCard
                              key={scenario.id}
                              scenario={scenario}
                              shared={true}
                           />
                        ))}
                     </div> : <Empty className="w-full mt-20" message="No scenarios have been shared with you yet." subMessage="Other teachers can share their scenarios with you to view and create assignments with." />
               )}
               {/* {tabs[tab] === Tabs.USERAI &&
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                     <AddNewCharacterCard />
                     {filteredUserCharacters.map((character) => (
                        <CharacterCard character={character} />
                     ))}
                  </div>
               } */}
            </div>}
         </div>
      </div>
   );
}

const RubricCard = ({ rubric, fromCourseta = false }) => {
   const { name, description } = rubric;
   const { showPopUp } = usePopUp();
   const { school } = useSchool();
   const path = (fromCourseta ? `gradeScripting/${rubric.id}` : `institutions/${school.id}/rubrics/${rubric.id}`);
   const { isSuperAdmin, user } = useAuth();
   const navigate = useNavigate();
   const { deleteCoursetaRubric, deleteUserMadeRubric } = useRubrics();

   const isAdmin = isSuperAdmin() || (!fromCourseta || rubric.admin === user.id)

   const handleDelete = async () => {
      console.log(fromCourseta);
      if (fromCourseta === true && isSuperAdmin()) {
         deleteCoursetaRubric(rubric.id);
      }
      else if (isAdmin) {
         deleteUserMadeRubric(rubric.id);
      }
   }

   return (
      <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between">
         <div className="w-full h-full bg-gray-300 rounded-lg mb-4">
            <Thumbnail thumbnailURL={`${path}/${rubric.thumbnail}`} altName={name} defaultImg={imgRubricPlaceholder} />
         </div>
         <div className="flex items-center justify-between">
            <div className="flex items-center">
               <h3 className="text-lg font-bold mb-2 text-gray-900 dark:text-white">{name}</h3>
            </div>
         </div>
         <div className="flex gap-4">
            {
               ((!fromCourseta && isAdmin) || fromCourseta === true && isSuperAdmin())
                  ?
                  <>
                     <button
                        className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        onClick={(e) => {
                           e.preventDefault();
                           if (fromCourseta) {
                              navigate(`rubric/${rubric.id}?courseta`);
                           } else {
                              navigate(`rubric/${rubric.id}`);
                           }
                        }}
                     >
                        Edit Rubric Nodes
                     </button>
                     <button
                        className="text-gray-500 hover:text-blue-500"
                        onClick={(e) => {
                           e.preventDefault();
                           showPopUp(<EditRubricPopup fromCourseta={fromCourseta} id={rubric.id} oldName={rubric.name} oldDesc={rubric.description} />);
                        }}
                     >
                        <HiPencil title="Edit" />
                     </button>
                     <DeleteButton iconOnly={true} onDelete={handleDelete} />
                  </>
                  :
                  <button
                     className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                     onClick={(e) => {
                        e.preventDefault();
                        if (fromCourseta) {
                           navigate(`rubric_view/${rubric.id}?courseta`);
                        } else {
                           navigate(`rubric_view/${rubric.id}`);
                        }
                     }}
                  >
                     View Rubric Nodes
                  </button>
            }
         </div>

         {/* {(!fromCourseta || isAdmin) && <div className="flex gap-4"><button
                onClick={(e) => {
                    e.preventDefault();
                    navigate(`rubric_view/${rubric.id}`);
                }}
                className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
            >
                View Rubric
            </button>
                {isAdmin && <button
                    className="text-gray-500 hover:text-blue-500"
                    onClick={(e) => {
                        //e.preventDefault();
                        //navigate(`rubric/${rubric.id}`);
                    }}
                >
                    <HiPencil title="Edit" />
                </button>}
                { <DeleteButton iconOnly={true} onDelete={handleDelete} /> }
            </div>} */}
      </div>
   );
};

const CharacterCard = ({ character, fromCourseta = false }) => {
   const { name, description, brainName } = character;

   const { school } = useSchool();
   const { isSuperAdmin, user } = useAuth();
   const navigate = useNavigate();
   const { deleteCharacter } = useInworld();

   const isAdmin = isSuperAdmin() || (!fromCourseta || character.admin === user.id)

   const characterId = brainName.split("/").pop();

   const path = (fromCourseta ? `characters/${character.id}` : `institutions/${school.id}/characters/${character.id}`);

   const handleDelete = async () => {
      await deleteCharacter(character.id, brainName, fromCourseta);
   }

   return <div
      key={characterId}
      className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between"
   >
      <div className="w-full h-full bg-gray-300 rounded-lg mb-4">
         <Thumbnail thumbnailURL={`${path}/${character.thumbnail}`} altName={character.name} defaultImg={imgCharacterPlaceholder} />
      </div>
      <div className="flex items-center justify-between">
         <div className="flex items-center">
            <h3 className="text-lg font-bold mb-2 text-gray-900 dark:text-white">{name}</h3>
            <div className="relative group p-2">
               <button
                  className="text-sm text-gray-600 dark:text-gray-400 underline hover:text-gray-800 dark:hover:text-gray-200"
               >
                  (i)
               </button>
               {/* Hover Info Bubble */}
               <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm p-3 rounded-lg shadow-lg z-10 max-w-xs w-max">
                  {description}
               </div>
            </div>
         </div>
         <td className="px-6 py-4">
            <span
               className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${character.role === CharacterRoles.PATIENT
                  ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                  : character.role === CharacterRoles.FAMILY_MEMBER
                     ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300' : 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                  }`}
            >
               {character.role}
            </span>
         </td>
      </div>
      {/* <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{description}</p> */}

      {(!fromCourseta || isAdmin) && <div className="flex gap-4"><button
         onClick={(e) => {
            e.preventDefault();
            if (fromCourseta) {
               navigate(`character/${characterId}?courseta`);
            }
            else {
               navigate(`character/${characterId}`);
            }
         }}
         className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
      >
         Edit Character
      </button>
         {/* Inworld y u so bad */}
         {/* <DeleteButton iconOnly={true} onDelete={handleDelete} /> */}
      </div>}
   </div>
}

const GradingRubric = () => {
   const [actions, setActions] = useState([
      {
         id: Date.now(),
         items: [
            {
               id: Date.now(),
               title: "",
               points: "",
               checkIf: "",
               selectOption: "",
               actionTitle: "",
               actionDescription: "",
            },
         ],
      },
   ]);

   const addAction = () => {
      setActions([
         ...actions,
         {
            id: Date.now(),
            items: [
               {
                  id: Date.now(),
                  title: "",
                  points: "",
                  checkIf: "",
                  selectOption: "",
                  actionTitle: "",
                  actionDescription: "",
               },
            ],
         },
      ]);
   };

   const duplicateAction = (actionId) => {
      const actionToDuplicate = actions.find((action) => action.id === actionId);
      if (actionToDuplicate) {
         const duplicatedAction = {
            ...actionToDuplicate,
            id: Date.now(),
            items: actionToDuplicate.items.map((item) => ({
               ...item,
               id: Date.now() + Math.random(),
            })),
         };
         setActions([...actions, duplicatedAction]);
      }
   };

   const removeAction = (actionId) => {
      setActions(actions.filter((action) => action.id !== actionId));
   };

   const addItem = (actionId) => {
      setActions(
         actions.map((action) =>
            action.id === actionId
               ? {
                  ...action,
                  items: [
                     ...action.items,
                     {
                        id: Date.now(),
                        title: "",
                        points: "",
                        checkIf: "",
                        selectOption: "",
                        actionTitle: "",
                        actionDescription: "",
                     },
                  ],
               }
               : action
         )
      );
   };

   const removeItem = (actionId, itemId) => {
      setActions(
         actions.map((action) =>
            action.id === actionId
               ? {
                  ...action,
                  items: action.items.filter((item) => item.id !== itemId),
               }
               : action
         )
      );
   };

   return (
      <div className="w-full bg-gray-100 dark:bg-gray-900 min-h-screen p-6">
         <h3 className="font-semibold text-lg pt-10 justify-center flex">Grading Rubrics</h3>
         <div className="p-10 flex items-center justify-between pt-2">
            <div className="flex w-1/5">
               <ReturnButton customLabel="Exit" />
            </div>
            <div className="flex w-1/5 justify-end">
               <SaveButton />
            </div>
         </div>
         {actions.map((action) => (
            <div
               key={action.id}
               className="w-full bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 flex mb-6"
            >
               {/* Left Panel */}
               <div className="w-1/4 bg-blue-50 border border-blue-200 rounded-lg p-4 relative">
                  <h4 className="text-lg font-semibold text-blue-700 mb-4">Action</h4>
                  <div className="absolute top-4 right-4 flex space-x-2">
                     <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => duplicateAction(action.id)}
                     >
                        <HiClipboardCopy />
                     </button>
                     <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => removeAction(action.id)}
                     >
                        <HiTrash />
                     </button>
                  </div>
                  <div className="mb-4">
                     <label className="block text-sm font-medium text-blue-700 mb-1">Type</label>
                     <select className="w-full border border-blue-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none">
                        <option>Scale</option>
                        <option>Pass/Fail</option>
                     </select>
                  </div>
                  <div className="mb-4">
                     <label className="block text-sm font-medium text-blue-700 mb-1">Event Trigger</label>
                     <input
                        type="text"
                        className="w-full border border-blue-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Washes Hands"
                     />
                  </div>
                  <div className="mb-4">
                     <label className="block text-sm font-medium text-blue-700 mb-1">Action Title</label>
                     <input
                        type="text"
                        className="w-full border border-blue-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Washes Hands"
                     />
                  </div>
                  <div className="mb-4">
                     <label className="block text-sm font-medium text-blue-700 mb-1">Action Description</label>
                     <textarea
                        className="w-full border border-blue-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Lorem ipsum"
                     ></textarea>
                  </div>
                  {/* <button className="bg-blue-500 text-white w-full px-4 py-2 rounded-lg hover:bg-blue-600">
                     Customize Trigger
                  </button> */}
               </div>

               {/* Right Items */}
               <div className="w-3/4 grid grid-cols-4">
                  {action.items.map((item) => (
                     <div key={item.id} className="bg-white border border-gray-300 rounded-lg p-4 shadow">
                        <div className="flex justify-between items-center mb-2">
                           <label className="block text-sm font-medium text-gray-700">Title</label>
                           <button
                              className="text-red-500 hover:text-red-700"
                              onClick={() => removeItem(action.id, item.id)}
                           >
                              <HiTrash />
                           </button>
                        </div>
                        <input
                           type="text"
                           className="w-full border border-gray-300 rounded-lg px-2 py-1 mb-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                           placeholder="Enter title"
                        />
                        <div className="mb-2">
                           <label className="block text-sm font-medium text-gray-700 mb-1">Points</label>
                           <input
                              type="number"
                              className="w-full border border-gray-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                              placeholder="Enter points"
                           />
                        </div>
                        <div className="mb-2">
                           <label className="block text-sm font-medium text-gray-700 mb-1">Check If</label>
                           <select className="w-full border border-gray-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none">
                              <option>Assessment 1</option>
                              <option>Assessment 2</option>
                           </select>
                        </div>
                        <div className="mb-2">
                           <label className="block text-sm font-medium text-gray-700 mb-1">Select Option</label>
                           <select className="w-full border border-gray-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none">
                              <option>Option here</option>
                              <option>Option 1</option>
                              <option>Option 2</option>
                           </select>
                        </div>
                        <div className="mb-2">
                           <label className="block text-sm font-medium text-gray-700 mb-1">Action Title</label>
                           <input
                              type="text"
                              className="w-full border border-gray-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                              placeholder="Action here"
                           />
                        </div>
                        <div className="mb-4">
                           <label className="block text-sm font-medium text-gray-700 mb-1">Action Description</label>
                           <textarea
                              className="w-full border border-gray-300 rounded-lg px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                              placeholder="Lorem ipsum"
                           ></textarea>
                        </div>
                        {/* <button className="bg-blue-500 text-white w-full px-4 py-2 rounded-lg hover:bg-blue-600">
                           Customize Trigger
                        </button> */}
                     </div>
                  ))}
                  {action.items.length < 4 && (
                     <div
                        className="flex items-center justify-center bg-blue-100 rounded-lg p-4 shadow cursor-pointer hover:bg-blue-200"
                        onClick={() => addItem(action.id)}
                     >
                        <span className="text-blue-500 font-semibold text-lg">+ Add Item</span>
                     </div>
                  )}
               </div>
            </div>
         ))}
         <div className="flex justify-center mt-6">
            <button
               className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600"
               onClick={addAction}
            >
               + Add Action
            </button>
         </div>
      </div>
   );
};

const CharacterLibrary = ({ aiTab, setAITab, characters }) => {
   const { role } = useAuth();
   const { loading } = useInworld();

   return (<div>
      <div>
         <TabsUnderlined tabs={aiTabs} activeTab={aiTab} setActiveTab={setAITab} />
      </div>
      {loading ? <SkeletonLoaderCard /> : <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
         {/*Big yikes code : D */}
         {/* **insert chewbacca.gif** */}
         {role === Roles.ADMIN && aiTab === 0 && <AddNewCharacterCard fromCourseta={true} />}
         {aiTab === 1 && <AddNewCharacterCard fromCourseta={false} />}
         {characters.map((character) => (
            <CharacterCard character={character} fromCourseta={aiTab === 0} />
         ))}
      </div>}
   </div>);
}

const LibraryCard = ({ scenario, fromCourseta = false, shared = false }) => {
   const { isSuperAdmin, user } = useAuth();
   const { showPopUp } = usePopUp();
   const { school } = useSchool();
   const { deleteScenario } = useScenarios();
   const { showNotification } = useNotification();
   const navigate = useNavigate();

   const isAdmin = isSuperAdmin() || (!fromCourseta || scenario.admin === user.id)

   const handleScenarioClick = () => {
      // if (fromCourseta && !school.availableScenarios.includes(scenario.id)) {
      //    showNotification(NotificationTypes.WARNING, "This scenario is not in your schools available scenarios.");
      //    return;
      // }
      navigate(`scenario/${scenario.id}${fromCourseta ? "?courseta" : ""}`);
   };

   const handleCreateTemplate = () => {
      showPopUp(<CreateScenario fromCourseta={false} scenario={scenario} />);
   };

   const handleScenarioInfo = () => {
      showPopUp(<ScenarioInfo scenario={scenario} fromCourseta={fromCourseta} />);
   };

   const handleCreateAssignment = () => {
      showPopUp(<CreateAssignment scenarioId={scenario.id} />);
   };

   const handleDeleteConfirm = async () => {
      await deleteScenario(scenario.id, fromCourseta);
   }

   const handleDelete = () => {
      showPopUp(<ConfirmPopup color={'red'} description={"Are you sure you want to delete this scenario. This action is irreversible."} icon={<HiExclamationTriangle />} label={"Delete"} onConfirm={handleDeleteConfirm} />);
   }

   const handleShare = () => {
      showPopUp(<ShareScenario scenario={scenario} />);
   };

   return (
      <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col relative">
         <div className="w-full h-64 aspect-square flex">
            <Thumbnail
               altName={scenario.name}
               thumbnailURL={`${fromCourseta ? `scenarios` : `institutions/${school.id}/scenarios`}/${scenario.id}/${scenario.thumbnail}`}
            />
         </div>

         {/* Card content */}
         <div className="p-4 flex flex-grow flex-col justify-between">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white truncate">
                  {scenario.name}
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mb-4">{scenario.summary}</p>
            </div>

            <div className="relative">
               <Dropdown
                  label={
                     <button className="flex items-center justify-center w-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium mt-4">
                        See Options
                        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                     </button>
                  }
                  inline={true}
                  arrowIcon={false}
                  className="absolute top-full mt-2 w-max z-50"
               >
                  <DropdownItem onClick={() => handleScenarioInfo()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> See Info
                  </DropdownItem>

                  {(isAdmin || !fromCourseta) && !shared && (
                     <DropdownItem onClick={() => handleScenarioClick()}>
                        <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Edit
                     </DropdownItem>
                  )}

                  {!shared && <DropdownItem onClick={() => handleCreateTemplate()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Duplicate
                  </DropdownItem>}

                  <DropdownItem onClick={() => handleCreateAssignment()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Create Assignment
                  </DropdownItem>

                  {!fromCourseta && !shared && <DropdownItem onClick={() => handleShare()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Share
                  </DropdownItem>}

                  {(!fromCourseta || isAdmin) && !shared && <DropdownItem onClick={() => handleDelete()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2 text-red-500" />{" "}
                     <div className="text-red-500">Delete</div>
                  </DropdownItem>}
               </Dropdown>
            </div>
         </div>
      </div>
   );

};

const ScenarioInfo = ({ scenario, fromCourseta = false }) => {
   const { showPopUp } = usePopUp();
   const { school } = useSchool();
   const [url, setUrl] = useState(null);

   const path = fromCourseta ? `scenarios/${scenario.id}` : `institutions/${school.id}/scenarios/${scenario.id}`

   useEffect(() => {
      const fetchUrl = async () => {
         const u = await getFileUrl(`${path}/${scenario.info}`);
         setUrl(u);
      }
      fetchUrl();
   }, [])

   const handleDownload = () => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank'); // Opens the PDF in a new tab
      link.setAttribute('rel', 'noopener noreferrer'); // Adds security measures
      link.setAttribute('download', `${scenario.name}.pdf`); // Sets the default download name for the PDF
      link.click(); // Triggers the download
   };

   const onClose = () => {
      showPopUp(null);
   }

   return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-8 z-50">
         <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-6">
            <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg">
               <h2 className="text-xl font-semibold">Scenario Info {scenario.name}</h2>
               <div className="flex space-x-4">
                  {/* Download Button */}
                  {url && <button
                     onClick={handleDownload}
                     className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg flex items-center"
                  >
                     Download PDF
                  </button>}
                  <button onClick={onClose} className="text-white hover:text-gray-300">
                     <HiX className="h-6 w-6" />
                  </button>
               </div>
            </div>
            <div className="p-6 bg-white max-h-[80vh] overflow-y-auto">
               {/* Assuming PDFViewer is another component */}
               <PDFViewer pdfUrl={url} />
            </div>
         </div>
      </div>
   );
};

const AddNewRubricCard = ({ fromCourseta = false }) => {
   const { showPopUp } = usePopUp();

   const handleCreate = () => {
      showPopUp(<CreateRubricPopup fromCourseta={fromCourseta} />);
   }

   return (
      <div
         onClick={handleCreate}
         className="cursor-pointer p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
      >
         {/* Placeholder Image/Graphic */}
         <div className="flex-grow flex items-center justify-center">
            <span className="text-blue-500 text-6xl">+</span>
         </div>

         {/* Card content */}
         <div className="flex-grow p-4 flex flex-col justify-between text-center">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white">
                  Create New
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mt-2">
                  Click Here to Create a New Rubric
               </p>
            </div>
         </div>
      </div>
   );
}

const AddNewCharacterCard = ({ fromCourseta = false }) => {
   const { showPopUp } = usePopUp();

   const handleCreate = () => {
      showPopUp(<CreateCharacter fromCourseta={fromCourseta} />);
   }

   return (
      <div
         onClick={handleCreate}
         className="cursor-pointer p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
      >
         {/* Placeholder Image/Graphic */}
         <div className="flex-grow flex items-center justify-center">
            <span className="text-blue-500 text-6xl">+</span>
         </div>

         {/* Card content */}
         <div className="flex-grow p-4 flex flex-col justify-between text-center">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white">
                  Create New
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mt-2">
                  Click Here to Create a New AI Character
               </p>
            </div>
         </div>
      </div>
   );
}

const AddNewCourseCard = ({ label1, label2, fromCourseta = false }) => {
   const { showPopUp } = usePopUp();

   const handleCreate = () => {
      showPopUp(<CreateScenario fromCourseta={fromCourseta} />);
   }

   return (
      <div
         onClick={handleCreate}
         className="cursor-pointer p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
      >
         {/* Placeholder Image/Graphic */}
         <div className="flex-grow flex items-center justify-center">
            <span className="text-blue-500 text-6xl">+</span>
         </div>

         {/* Card content */}
         <div className="flex-grow p-4 flex flex-col justify-between text-center">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white">
                  {label1}
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mt-2">
                  {label2}
               </p>
            </div>
         </div>
      </div>
   );
};

const CreateRubricPopup = ({ fromCourseta = false }) => {
   const [name, setName] = useState('');
   const [desc, setDesc] = useState('');
   const { showPopUp } = usePopUp();
   const { addCoursetaRubric, addUserMadeRubric } = useRubrics();

   const handleSubmit = () => {
      if (fromCourseta === true) {
         addCoursetaRubric(name, desc);
      } else {
         addUserMadeRubric(name, desc);
      }

      onClose();
   }

   const onClose = () => {
      showPopUp(null);
   }

   return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
         <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
            <div className="overflow-auto max-h-[80vh]">
               <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Rubric</h3>
                  <button
                     onClick={onClose}
                     type="button"
                     className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                     style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                  >
                     <span className="sr-only">Close modal</span>
                     <HiX />
                  </button>
               </div>

               <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Rubric name</label>
                     <input
                        type="text"
                        id="gradesscriptingName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Enter Rubric name"
                        required
                     />
                     <label className="block mt-4 mb-2 text-sm text-gray-900 dark:text-gray-300">Rubric description</label>
                     <textarea
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        type='text'
                        id='gradesscriptingNameDescription'
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        placeholder="Enter Rubric description"
                     />
                  </div>

                  <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                     Create Rubric
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

const EditRubricPopup = ({ fromCourseta = false, id, oldName, oldDesc }) => {
   const [name, setName] = useState(oldName);
   const [desc, setDesc] = useState(oldDesc);
   const { showPopUp } = usePopUp();
   const { updateCoursetaRubricMeta, updateUserMadeRubricMeta } = useRubrics();

   const handleSubmit = () => {
      if (fromCourseta === true) {
         updateCoursetaRubricMeta(id, name, desc);
      } else {
         updateUserMadeRubricMeta(id, name, desc);
      }

      onClose();
   }

   const onClose = () => {
      showPopUp(null);
   }

   return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
         <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
            <div className="overflow-auto max-h-[80vh]">
               <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Edit Rubric</h3>
                  <button
                     onClick={onClose}
                     type="button"
                     className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                     style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                  >
                     <span className="sr-only">Close modal</span>
                     <HiX />
                  </button>
               </div>

               <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Rubric name</label>
                     <input
                        type="text"
                        id="gradesscriptingName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Enter Rubric name"
                        required
                     />
                     <label className="block mt-4 mb-2 text-sm text-gray-900 dark:text-gray-300">Rubric description</label>
                     <textarea
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        type='text'
                        id='gradesscriptingNameDescription'
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        placeholder="Enter Rubric description"
                     />
                  </div>

                  <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                     Save
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

const CreateCharacter = ({ fromCourseta = false }) => {
   const { showPopUp } = usePopUp();
   const [name, setName] = useState("");
   const [description, setDescription] = useState("");
   const [motivation, setMotivation] = useState("");
   const [role, setRole] = useState(CharacterRoles.PATIENT);
   const { createCharacter } = useInworld();

   const onClose = () => {
      showPopUp(null);
   }

   const handleSubmit = async () => {
      onClose();
      await createCharacter(name, description, motivation, role, fromCourseta);
   }

   return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
         <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
            <div className="overflow-auto max-h-[80vh]">
               <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Character</h3>
                  <button
                     onClick={onClose}
                     type="button"
                     className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                     style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                  >
                     <span className="sr-only">Close modal</span>
                     <HiX />
                  </button>
               </div>

               <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character name</label>
                     <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm mb-4 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="John Doe"
                        required
                     />
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character description</label>
                     <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="shadow-sm mb-4 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="55 year old patient"
                        required
                     />
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character motivation</label>
                     <input
                        type="text"
                        value={motivation}
                        onChange={(e) => setMotivation(e.target.value)}
                        className="shadow-sm mb-4 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Wants to go home and answer the nurse's questions"
                        required
                     />
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character role</label>
                     <select
                        id="role"
                        value={role}
                        onChange={(e) => {
                           setRole(e.target.value)
                        }}
                        className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                     >
                        {Object.values(CharacterRoles).map((option) => (
                           <option key={option} value={option}>
                              {option}
                           </option>
                        ))}
                     </select>
                  </div>
                  <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                     Create Character
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

const CreateScenario = ({ fromCourseta = false, scenario = null }) => {
   const { showPopUp } = usePopUp();
   const [name, setName] = useState("");
   const { createScenario } = useScenarios();

   const onClose = () => {
      showPopUp(null);
   }

   const handleSubmit = async () => {
      onClose();
      await createScenario(name, fromCourseta, scenario);
   }

   return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
         <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
            <div className="overflow-auto max-h-[80vh]">
               <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">{scenario ? 'Duplicate' : 'Create'} {scenario ? scenario.name : "Scenario"}</h3>
                  <button
                     onClick={onClose}
                     type="button"
                     className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                     style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                  >
                     <span className="sr-only">Close modal</span>
                     <HiX />
                  </button>
               </div>

               <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Scenario name</label>
                     <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Enter scenario name"
                        required
                     />
                  </div>
                  <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                     {scenario ? 'Duplicate' : 'Create'} Scenario
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

const ShareScenario = ({ scenario }) => {
   const { user } = useAuth();
   const { users } = useUsers();
   const { updateShared } = useScenarios();
   const [selectedTeachers, setSelectedTeachers] = useState(scenario.shared ?? []);

   //Filter on teachers
   const teachers = users.filter(u => u.role === "Teacher" && u.id !== user.id);

   const { showPopUp } = usePopUp();

   const handleTeacherSelect = (teacherId) => {
      setSelectedTeachers(prevSelected =>
         prevSelected.includes(teacherId)
            ? prevSelected.filter(id => id !== teacherId)
            : [...prevSelected, teacherId]
      );
   };

   const handleShare = async () => {
      updateShared(scenario.id, selectedTeachers);
      onClose();
   };

   const onClose = () => {
      showPopUp(null);
   }

   return (
      <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
         <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
               <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Share Scenario</h3>
               <button onClick={onClose} className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  Close
               </button>
            </div>
            <div className="mb-4">
               <h4 className="text-md font-semibold text-gray-900 dark:text-gray-300">Select Teachers to Share</h4>
               {teachers.length > 0 ? (
                  <div className="max-h-64 overflow-y-auto">
                     {teachers.map(teacher => (
                        <div key={teacher.id} className="flex items-center mb-2">
                           <input
                              type="checkbox"
                              id={`teacher-${teacher.id}`}
                              checked={selectedTeachers.includes(teacher.id)}
                              onChange={() => handleTeacherSelect(teacher.id)}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                           />
                           <label htmlFor={`teacher-${teacher.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {teacher.firstName + ' ' + teacher.lastName} ({teacher.email})
                           </label>
                        </div>
                     ))}
                  </div>
               ) : (
                  <p className="text-sm text-gray-500 dark:text-gray-400">No teachers found in your school.</p>
               )}
            </div>
            <button
               onClick={handleShare}
               className="w-full flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300 space-x-2"
            >
               Share Template
            </button>
         </div>
      </div>
   );
}

export default Library;