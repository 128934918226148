import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Checkbox } from 'flowbite-react'; // Import Flowbite components
import { HiArrowRight, HiFilter } from 'react-icons/hi';
import useCourses from '../../hooks/useCourses';
import useGrades from '../../hooks/useGrades';
import useAuth from '../../hooks/useAuth';
import { calculateArrayFieldAverage } from '../../utils/analytics';


// Register the components in Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function CourseAnalyticsGraph({ }) {
    const { courses } = useCourses();
    const { grades, getGradesForCourse } = useGrades();
    const { user } = useAuth();

    const courseResults = courses.map((course) => {
        const courseGrades = getGradesForCourse(course.id);
        const accuracy = calculateArrayFieldAverage(courseGrades, "accuracyResult");
        const communication = calculateArrayFieldAverage(courseGrades, "communicationResult");
        const safety = calculateArrayFieldAverage(courseGrades, "safetyResult");
        const answers = calculateArrayFieldAverage(courseGrades, "answerResult");
        return { ...course, accuracy: accuracy, communication: communication, safety: safety, answers: answers }
    })

    const chartData = {
        labels: courseResults.map((course) => course.name),
        datasets: [
            {
                label: 'Accuracy',
                data: courseResults.map((course) => course.accuracy),
                backgroundColor: '#00bcd4',
                borderRadius: 10, // Add rounded corners to the bars
            },
            {
                label: 'Communication',
                data: courseResults.map((course) => course.communication),
                backgroundColor: '#1e88e5',
                borderRadius: 10, // Add rounded corners to the bars
            },
            {
                label: 'Safety',
                data: courseResults.map((course) => course.safety),
                backgroundColor: '#29b6f6',
                borderRadius: 10, // Add rounded corners to the bars
            },
            {
                label: 'Answers',
                data: courseResults.map((course) => course.answers),
                backgroundColor: '#4db6ac',
                borderRadius: 10, // Add rounded corners to the bars
            },
        ].filter(Boolean),
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Courses'
                },
                grid: {
                    display: false // Removes grid lines from x-axis
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Percentage'
                },
                beginAtZero: true,
                max: 100,
                ticks: {
                    stepSize: 10
                },
                grid: {
                    borderDash: [5, 5]
                }
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`
                }
            }
        }
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white"></h3>
                <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Average Course Performance</h4>
                {/* <button
                    className="text-blue-500 dark:text-blue-400 hover:underline flex flex-wrap gap-2"
                    onClick={handleNavigate}
                >
                    {label}
                    <HiArrowRight className='mt-1' />
                </button> */}
            </div>

            {/* Dropdowns Container */}
            <div className="flex space-x-4 mb-4">
                {/* Course Filter Dropdown */}
                {/* <Dropdown inline label={
                    <div className="flex items-center space-x-2">
                        <HiFilter className="text-3xl text-gray-600 dark:text-blue-400" />
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                            Filter by Courses
                        </span>
                    </div>
                }>
                    {mockCourses.map((course) => (
                        <Dropdown.Item key={course.className} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                            <Checkbox
                                id={course.className}
                                checked={selectedCourses.includes(course.className)}
                                onChange={() => {
                                    setSelectedCourses((prevSelected) =>
                                        prevSelected.includes(course.className)
                                            ? prevSelected.filter((course) => course !== course.className)
                                            : [...prevSelected, course.className]
                                    );
                                }}
                            />
                            {course.className}
                        </Dropdown.Item>
                    ))}
                </Dropdown> */}

                {/* Metric Filter Dropdown */}
                {/* <Dropdown inline label={
                    <div className="flex items-center space-x-2">
                        <HiFilter className="text-3xl text-gray-600 dark:text-blue-400" />
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                            Filter by Metrics
                        </span>
                    </div>
                }>
                    {['accuracy', 'communication', 'safety', 'answers'].map((metric) => (
                        <Dropdown.Item key={metric} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                            <Checkbox
                                id={metric}
                                name={metric}
                                checked={selectedFilters[metric]}
                                onChange={handleFilterChange}
                            />
                            {metric.charAt(0).toUpperCase() + metric.slice(1)}
                        </Dropdown.Item>
                    ))}
                </Dropdown> */}
            </div>

            {/* Chart Display */}
            {courseResults.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Bar data={chartData} options={chartOptions} />
                </div>
            ) : (
                <p className="text-gray-600 dark:text-gray-400">No grades available for the selected courses.</p>
            )}
        </div>

    );
}
