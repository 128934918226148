import React, { createContext, useState, useEffect } from 'react';
import { addDocument, deleteDocument, getDocument, subscribeCollection, updateDocument } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import Roles from '../utils/roles';
import useAuth from '../hooks/useAuth';
import { useNotification } from '../hooks/useNotification';
import { serverTimestamp } from 'firebase/firestore';
import { NotificationTypes } from '../utils/notifications';
import { storage } from '../firebase/firebase';
import { getBytes, getMetadata, listAll, ref, uploadBytes } from 'firebase/storage';

const ScenariosContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const ScenariosProvider = ({ children }) => {
    const { school } = useSchool();
    const { user, role } = useAuth();

    //Used only by admins, has all courseta scenarios regardless of school
    const [allScenarios, setAllScenarios] = useState([]);

    //Courseta scenarios (filtered by school's available scenarios)
    const [scenarios, setScenarios] = useState([]);
    const [scenariosMap, setScenariosMap] = useState(new Map());

    //Shared scenarios
    const [sharedScenarios, setSharedScenarios] = useState([]);
    const [sharedScenariosMap, setSharedScenariosMap] = useState(new Map());

    //User scenarios
    const [userMadeScenarios, setUserMadeScenarios] = useState([]);
    const [userScenariosMap, setUserScenariosMap] = useState(new Map());

    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification();

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school && user) {
            const unsubscribeCoursetaScenarios = subscribeCollection(`simulations`, (data) => {
                // console.log("Refreshed scenarios: ", data)
                // setLoading(false);
                if (data) {
                    console.log("Refreshed courseta scenarios: ", data);
                    const filteredScenarios = data.filter(s => s.fromCourseta == null || s.fromCourseta == true);
                    if (role === Roles.ADMIN) {
                        setAllScenarios(filteredScenarios);
                    }
                    if (school.availableScenarios && school.availableScenarios.length > 0) {
                        const available = filteredScenarios.filter((s) => school.availableScenarios.includes(s.id));
                        setScenarios(available);
                        setScenariosMap(new Map(available.map((item) => [item.id, item])));
                    }
                }
                else {
                    //No scenarios
                    setScenarios([]);
                    setScenariosMap(new Map());
                    setAllScenarios([]);
                }
            });

            const unsubscribeUserScenarios = subscribeCollection(`institutions/${school.id}/scenarios`, (data) => {
                // console.log("Refreshed scenarios: ", data)
                // setLoading(false);
                if (data) {
                    console.log("Refreshed user scenarios.");
                    //Only set scenarios that the user himself created unless I'm an admin account
                    // const filteredScenarios = data.filter(s => s.admin === user.id || role === Roles.ADMIN || role === Roles.SCHOOLADMIN || role === Roles.STUDENT);
                    setUserMadeScenarios(data);
                    setUserScenariosMap(new Map(data.map((item) => [item.id, item])));

                    //Get all shared scenarios
                    const shared = data.filter(s => s.admin !== user.id && s.shared.includes(user.id));
                    setSharedScenarios(shared);
                    setSharedScenariosMap(new Map(shared.map((item) => [item.id, item])));
                }
                else {
                    setUserMadeScenarios();
                    setUserScenariosMap(new Map());
                    setSharedScenarios([]);
                    setSharedScenariosMap(new Map());
                }
            });

            return () => {
                unsubscribeCoursetaScenarios();
                unsubscribeUserScenarios();
            }
        }
        else {
            console.log("Cleared scenarios");
            setScenarios([]);
            setScenariosMap(new Map());
            setUserMadeScenarios();
            setUserScenariosMap(new Map());
            // setLoading(true);
        }
    }, [school, user])

    const createScenario = async (name, fromCourseta, scenario = null) => {
        try {
            setLoading(true);
            const path = fromCourseta ? `simulations` : `institutions/${school.id}/scenarios`
            const newScenarioId = await addDocument(scenario ? { ...scenario, name: name, baseScenarioId: scenario.id, shared: [], admin: user.id } : { name: name, createdAt: serverTimestamp(), summary: "", thumbnail: "", info: "", prebriefing: "", prebriefingVideo: "", debriefing: [], debriefing_2: [], documentation: [], admin: user.id, shared: [] }, path);

            if (scenario) {
                // Copy storage files from the old scenario to the new one
                const oldStoragePath = scenariosMap.has(scenario.id) ? `scenarios/${scenario.id}` : `institutions/${school.id}/scenarios/${scenario.id}`;
                const newStoragePath = `institutions/${school.id}/scenarios/${newScenarioId}`;

                const listFiles = async (path) => {
                    const storageRef = ref(storage, path);
                    const listResult = await listAll(storageRef);
                    return listResult.items; // Return an array of files
                };

                const copyFile = async (fileRef, destinationPath) => {
                    const metadata = await getMetadata(fileRef);
                    const fileContent = await getBytes(fileRef); // Get file bytes
                    const destinationRef = ref(storage, destinationPath);
                    await uploadBytes(destinationRef, fileContent, { contentType: metadata.contentType }); // Upload file to the new path
                };

                const oldFiles = await listFiles(oldStoragePath);
                for (const file of oldFiles) {
                    const fileName = file.name; // Get the file name
                    const destinationPath = `${newStoragePath}/${fileName}`;
                    await copyFile(file, destinationPath);
                }
                console.log(`Successfully copied files from ${oldStoragePath} to ${newStoragePath}`);

                let scriptData = await getDocument('scripting', `simulations/${scenario.id}/scriptData`);
                if (scriptData == null) {
                    scriptData = await getDocument('scripting', `institutions/${school.id}/scenarios/${scenario.id}/scriptData`);
                }
                if (scriptData) {
                    await addDocument(scriptData, `${path}/${newScenarioId}/scriptData`, 'scripting');
                }
            }

            showNotification(NotificationTypes.SUCCESS, `Scenario: ${name} created successfully.`);
        }
        catch (error) {
            console.error("Error creating scenario: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.code}`);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    const deleteScenario = async (id, fromCourseta) => {
        try {
            setLoading(true);
            const path = fromCourseta ? `simulations` : `institutions/${school.id}/scenarios`
            await deleteDocument(id, path);
            showNotification(NotificationTypes.SUCCESS, `Scenario deleted successfully.`);
        }
        catch (error) {
            console.error("Error deleting scenario: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.code}`);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    const updateShared = async (id, newShared) => {
        try {
            setLoading(true);
            //Since you can only share non-courseta scenarios we don't have to check which path to use
            await updateDocument(id, `institutions/${school.id}/scenarios`, { shared: newShared });
            showNotification(NotificationTypes.SUCCESS, `Shared list updated successfully.`);
        }
        catch (error) {
            console.error("Error sharing scenario: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.code}`);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <ScenariosContext.Provider value={{ allScenarios, scenarios, loading, scenariosMap, userMadeScenarios, userScenariosMap, createScenario, deleteScenario, sharedScenarios, sharedScenariosMap, updateShared }}>
            {children}
        </ScenariosContext.Provider>
    );
}

export { ScenariosProvider, ScenariosContext };