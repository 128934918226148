import { createContext, useEffect, useState } from "react";
import useSchool from "../hooks/useSchool";
import useAuth from "../hooks/useAuth";
import { addDocument, deleteDocument, getDocument, subscribeCollection, updateDocument } from "../firebase/firestore";
import { serverTimestamp } from "firebase/firestore";

const RubricsContext = createContext();

const RubricsProvider = ({ children }) => {
    const { school } = useSchool();
    const { user, role } = useAuth();

    const [coursetaRubrics, setCoursetaRubrics] = useState([]);
    const [userMadeRubrics, setUserMadeRubrics] = useState([]);

    const addCoursetaRubric = async (name, description) => {
        const doc = { name: name, description: description, thumbnail: "", nodes: [], edges: [], createdAt: serverTimestamp() };
        try {
            const id = await addDocument(doc, `gradesScripting`);
            return id;
        }
        catch (error) {
            console.error("Error saving document for rubric: ", error);
        }
    }

    const updateCoursetaRubricMeta = async (id, name, description) => {
        const collectionPath = `gradesScripting`;
        try {
            var data = await getDocument(id, collectionPath);
            data.name = name;
            data.description = description;
            try {
                await updateDocument(id, collectionPath, data);
            }
                catch (error) {
                console.error("Error updating rubric: ", error);
            }
        }
        catch (error) {
            console.error("Error fetching rubric: ", error);
        }
    }

    const deleteCoursetaRubric = async (id) => {
        try {
            await deleteDocument(id, `gradesScripting`);
        }
         catch (error) {
            console.error("Error deleting rubric: ", error);
        }
    }

    const addUserMadeRubric = async (name, description) => {
        const doc = { name: name, description: description, thumbnail: "", nodes: [], edges: [], admin: user.id, createdAt: serverTimestamp() };
        try {
            const id = await addDocument(doc, `institutions/${school.id}/rubrics`);
            return id;
        }
        catch (error) {
            console.error("Error saving document for rubric: ", error);
        }
    }

    const updateUserMadeRubricMeta = async (id, name, description) => {
        const collectionPath = `institutions/${school.id}/rubrics`;
        try {
            var data = await getDocument(id, collectionPath);
            data.name = name;
            data.description = description;
            try {
                await updateDocument(id, collectionPath, data);
            }
                catch (error) {
                console.error("Error updating rubric: ", error);
            }
        }
        catch (error) {
            console.error("Error fetching rubric: ", error);
        }
    }

    const deleteUserMadeRubric = async (id) => {
        try {
            await deleteDocument(id, `institutions/${school.id}/rubrics`);
        }
         catch (error) {
            console.error("Error updating rubric: ", error);
        }
    }

    useEffect(() => { 
        if (school && user) {
            // Subscribe to FB collections
            const unsubscribeCoursetaRubrics = subscribeCollection('gradesScripting', (data) => { 
                if (data) {
                    console.log("Refreshed courseta rubrics: ", data);
                    setCoursetaRubrics(data);
                } else {
                    setCoursetaRubrics([]);
                }
            });

            const unsubscribeUserMadeRubrics = subscribeCollection(`institutions/${school.id}/rubrics`, (data) => {
                if (data) {
                    const userData = data.filter(doc => doc.admin === user.id);
                    console.log("Refreshed user rubrics: ", userData);
                    setUserMadeRubrics(userData);
                } else {
                    setUserMadeRubrics([]);
                }
            });

            return () => {
                unsubscribeCoursetaRubrics();
                unsubscribeUserMadeRubrics();
            }
        } else {
            console.log("Cleared all rubrics");
            setCoursetaRubrics([]);
            setUserMadeRubrics([]);
        }
    }, [school, user]);

    return (
        <RubricsContext.Provider value={{ coursetaRubrics, userMadeRubrics, addCoursetaRubric, updateCoursetaRubricMeta, deleteCoursetaRubric, addUserMadeRubric, updateUserMadeRubricMeta, deleteUserMadeRubric }}>
            {children}
        </RubricsContext.Provider>
    );
};

export { RubricsProvider, RubricsContext };