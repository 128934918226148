import React, { useCallback, useEffect, } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useReactFlow,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import Sidebar from "./Sidebar";
import EffectNode from "./nodes/EffectNode";
import CheckNode from "./nodes/CheckNode";
import DelayNode from "./nodes/DelayNode";
import EventNode from "./nodes/EventNode";
import CustomEdge from "./CustomEdge";
import { useNavigate } from 'react-router-dom';
import useScripting from "../../hooks/useScripting";

const nodeTypes = {
  effect: EffectNode,
  check: CheckNode,
  delay: DelayNode,
  event: EventNode,
};
const edgeTypes = { custom: CustomEdge };

function VisualScripting({ saveable }) {
  const { nodes, edges, onNodesChange, onEdgesChange, save, addEdge, addNode, scenarioId } = useScripting();
  const { screenToFlowPosition } = useReactFlow();
  const navigate = useNavigate();

  const onConnect = useCallback(
    (params) => {
      //console.log("Connecting nodes with params:", params);
      addEdge(params);
    },
    [addEdge]
  );

  // Remove the resizeObserver error (REACT FLOW BUG)
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
          "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = useCallback((event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData("application/reactflow");
    if (nodeTypes[type] == null) return;
    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    addNode(type, position);
  }, [screenToFlowPosition, addNode]);

    return (
        
        <div className="flex h-[calc(100vh-6rem)] bg-gray-50 dark:bg-gray-900">
        { /* Rens: h-[calc(100vh-...)] instead of h-full because the header/top bar wasn't taken into account */ }
        {/* <div className="flex h-screen bg-gray-50 dark:bg-gray-900"> */}
        <Sidebar />
      <div className="flex-grow h-full relative reactflow-wrapper">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          // onNodeClick={handleNodeClick}
          // onEdgeClick={handleEdgeClick}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
        <div className="absolute top-4 right-4 flex space-x-2">
            {saveable === true && <button
                onClick={save}
                className="flex items-center justify-center p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400"
            >
                Save Nodes
            </button>}
          {!scenarioId && <button
            onClick={() => navigate(-1)}
            className="flex items-center justify-center p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400"
          >
            Return
          </button>}
        </div>
      </div>
    </div>
  );
}

export default ({ saveable = true }) => (
  <ReactFlowProvider>
        <VisualScripting saveable={saveable} />
  </ReactFlowProvider>
);
