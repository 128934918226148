import { useContext, useEffect, useState } from "react";
import { ScriptingContext } from "../contexts/ScriptingContext";
import { useParams, useSearchParams } from "react-router-dom";
import { useEdgesState, useNodesState } from "reactflow";
import { getDocument } from "../firebase/firestore";
import useAuth from "./useAuth";
import Roles from "../utils/roles";
import useSchool from "./useSchool";

const useScripting = () => {
    const context = useContext(ScriptingContext);

    const { school } = useSchool();
    const [searchParams] = useSearchParams();
    const { role } = useAuth();

    const { medicationId, conditionId, gradesscriptId, scenarioId } = useParams();

    useEffect(() => {
        const fetchScenarioScriptData = async () => {
            console.log("getting scenario data");
            try {
                const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;
                const path = fromCourseta ? `simulations/${scenarioId}/scriptData` : `institutions/${school.id}/scenarios/${scenarioId}/scriptData`
                const data = await getDocument('scripting', path);
                if(data)
                    context.loadData(data);
            }
            catch (error) {
                console.error("Error loading scenario script data: ", error);
            }
        }

        if (medicationId) {
            const data = getMedicationById(medicationId);
            context.loadData(data);
        }
        else if (conditionId) {
            const data = getConditionById(conditionId);
            context.loadData(data);
        }
        else if (gradesscriptId) {
            const data = getGradeScriptById(gradesscriptId);
            context.loadData(data);
        }
        else if (scenarioId) {
            // const data = getScenarioScripting(scenarioId);
            fetchScenarioScriptData();
        }
        else {
            console.log("Cleared scripting data.")
            context.loadData(null);
        }
    }, [medicationId, conditionId, gradesscriptId, scenarioId]);

    const save = () => {
        if (medicationId) {
            context.saveMedication(medicationId);
        }
        else if (conditionId) {
            context.saveCondition(conditionId);
        }
        else if (gradesscriptId) {
            const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;
            const path = fromCourseta ? `gradesScripting` : `institutions/${school.id}/rubrics`;
            context.saveGradeScripting(gradesscriptId, path);
        }
        else if (scenarioId) {
            const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;
            const path = fromCourseta ? `simulations/${scenarioId}/scriptData/` : `institutions/${school.id}/scenarios/${scenarioId}/scriptData`
            context.saveScenarioScripting('scripting', path);
        }
    }

    // useEffect(() => {
    //     if (conditionId) {
    //         const data = getConditionById(conditionId);
    //         setNodes(data.nodes ?? [])
    //         setEdges(data.edges ?? [])
    //     }
    //     else {
    //         setNodes([])
    //         setEdges([])
    //     }
    // }, [conditionId]);

    // useEffect(() => {
    //     if (gradesscriptId) {
    //         const data = getGradeScriptById(gradesscriptId);
    //         setNodes(data.nodes ?? [])
    //         setEdges(data.edges ?? [])
    //     }
    //     else {
    //         setNodes([])
    //         setEdges([])
    //     }
    // }, [gradesscriptId]);

    const getMedicationById = (id) => {
        if (!context.medicationsMap || context.medicationsMap.size <= 0) {
            console.error("Error trying to get medication by ID: No medications!");
            return;
        }

        return context.medicationsMap.get(id);
    }

    const getConditionById = (id) => {
        if (!context.conditionsMap || context.conditionsMap.size <= 0) {
            console.error("Error trying to get condition by ID: No conditions!");
            return;
        }

        return context.conditionsMap.get(id);
    }

    const getGradeScriptById = (id) => {
        if (!context.gradesMap || context.gradesMap.size <= 0) {
            console.error("Error trying to get grade script by ID: No grade script data!");
            return;
        }

        return context.gradesMap.get(id);
    }

    return { ...context, getMedicationById, getConditionById, getGradeScriptById, save, medicationId, conditionId, gradesscriptId, scenarioId };
}

export default useScripting;