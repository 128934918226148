import { useState } from "react";
import useCourses from "../../hooks/useCourses";
import { usePopUp } from "../../hooks/usePopUp";
import useRegistrations from "../../hooks/useRegistrations";
import useSchool from "../../hooks/useSchool";
import useAuth from "../../hooks/useAuth";
import Roles from "../../utils/roles";

const InviteUser = () => {
    const { school } = useSchool();
    const [registration, setRegistration] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "Student",
        courses: [],
        schoolId: school.id
    });

    const { showPopUp } = usePopUp();
    const { addRegistration } = useRegistrations();
    const { courses } = useCourses();
    const { role } = useAuth();

    const availableRoles = role === Roles.ADMIN ? ["Student", "Teacher", "School Admin"] : ["Student", "Teacher"];

    const handleSubmit = async (e) => {
        e.preventDefault();
        showPopUp(null);
        await addRegistration(registration);
    }

    return (
        <div
            id="createUserdefaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div className="relative w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800">
                {/* Header */}
                <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Add User
                    </h3>
                    <button
                        onClick={() => {
                            showPopUp(null);
                        }}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>

                {/* Form */}
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-6 sm:grid-cols-2">
                        {/* First Name */}
                        <div>
                            <label
                                htmlFor="firstname"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                First Name
                            </label>
                            <input
                                type="text"
                                id="firstname"
                                placeholder="John"
                                required
                                value={registration.firstName ?? ""}
                                onChange={(e) =>
                                    setRegistration({
                                        ...registration,
                                        firstName: e.target.value,
                                    })
                                }
                                className="w-full p-2.5 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                        </div>

                        {/* Last Name */}
                        <div>
                            <label
                                htmlFor="lastname"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Last Name
                            </label>
                            <input
                                type="text"
                                id="lastname"
                                placeholder="Doe"
                                required
                                value={registration.lastName ?? ""}
                                onChange={(e) =>
                                    setRegistration({
                                        ...registration,
                                        lastName: e.target.value,
                                    })
                                }
                                className="w-full p-2.5 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                        </div>

                        {/* Email */}
                        <div>
                            <label
                                htmlFor="email"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                placeholder="name@company.com"
                                required
                                value={registration.email ?? ""}
                                onChange={(e) =>
                                    setRegistration({
                                        ...registration,
                                        email: e.target.value,
                                    })
                                }
                                className="w-full p-2.5 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                        </div>

                        {/* Assign Role */}
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="role"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Assign Role
                            </label>
                            <div className="flex flex-wrap gap-2">
                                {availableRoles.map((roleName, index) => (
                                    <div key={index} className="flex items-center">
                                        <input
                                            type="radio"
                                            id={`role-${roleName}`}
                                            name="role"
                                            value={roleName}
                                            checked={registration.role === roleName}
                                            onChange={() =>
                                                setRegistration({
                                                    ...registration,
                                                    role: roleName,
                                                })
                                            }
                                            className="w-4 h-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor={`role-${roleName}`}
                                            className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                                        >
                                            {roleName}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Available Courses */}
                        {courses.length > 0 && (
                            <div className="sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Available Courses
                                </label>
                                <div className="max-h-40 overflow-y-auto bg-gray-50 border rounded-lg p-2 dark:bg-gray-700 dark:border-gray-600">
                                    {courses.map((course) => (
                                        <div key={course.id} className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id={`course-${course.id}`}
                                                value={course.id}
                                                checked={registration.courses.includes(
                                                    course.id
                                                )}
                                                onChange={(e) => {
                                                    const { checked, value } = e.target;
                                                    setRegistration((prev) => ({
                                                        ...prev,
                                                        courses:
                                                            checked
                                                                ? [...prev.courses, value]
                                                                : prev.courses.filter(
                                                                    (id) => id !== value
                                                                ),
                                                    }));
                                                }}
                                                className="form-checkbox text-primary-600 rounded focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            <label
                                                htmlFor={`course-${course.id}`}
                                                className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                                            >
                                                {course.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Buttons */}
                    <div className="flex justify-end mt-6 space-x-4">
                        <button
                            type="submit"
                            className="px-5 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600"
                        >
                            Add User
                        </button>
                        <button
                            type="button"
                            onClick={() => showPopUp(null)}
                            className="px-5 py-2.5 text-sm font-medium text-gray-500 bg-white border rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:bg-gray-600"
                        >
                            Discard
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default InviteUser;